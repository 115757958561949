<template>
  <div class="Page inner">
    <div class="innerPageHead">
      <div class="innerPageBanner coffee">
        <b-container>
          <b-row
            align-h="center"
            align-v="center"
            cols="2"
          >
            <b-col class="text-center">
              <div
                class="innerPageBannerPic pt-md-5 px-md-5 pb-md-3 pt-5 px-4 pb-2"
              >
                <b-img
                  :src="require('@/assets/imgs/icon_interpage_coffee.png')"
                  fluid
                  alt="咖啡館"
                ></b-img>
              </div>
              <h2 class="innerPageBannerTitle">咖啡業者</h2>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container>
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-container>
    </div>

    <section class="shopIntro">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col lg="8">
            <div class="shopIntroText">
              <p>
                魚池鄉平均海拔700公尺，富含露水霧氣的環境適合咖啡生長，也種植出擁有魚池獨特風味的咖啡，整體味道乾淨、順口、如：前段的果酸風味、中段的厚度、後段的回甘，品質優良曾於國際比賽獲獎，在2011年日本虹吸世界盃大賽，台灣選手採用魚池鄉咖啡豆參賽勇奪亞軍，讓「咖啡教母」娥娜努森 (Erna Knutsen)特地走訪魚池鄉的咖啡園，讚嘆魚池咖啡的香氣與甜感，為日月潭咖啡打響知名度！
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div class="shopListGroup">
      <b-container>
        <b-row cols="2" cols-sm="2" cols-md="3" cols-lg="4">
          <b-col
            class="shopListItem"
            v-for="item in storeList"
            :key="item.SO_ID"
          >
            <b-link :to="'Shopinfo?id=' + item.SO_ID" class="drop-shadow">
              <div class="shopListPic">
                <b-img :src="item.MainImgUrl" fluid :alt="item.ME_NAME"></b-img>
              </div>
              <div class="shopListTitle">
                <h3>{{ item.SO_NAME }}</h3>
              </div>
            </b-link>
          </b-col>
        </b-row>
        <div class="page">
          <b-pagination
            class="pagination light-theme simple-pagination"
            v-model="pagination.page"
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-container>
    </div>

    <ul id="myMenu">
      <li>
        <a href="/" tooltip="首頁" flow="left"
          ><span class="icon-home3" title="返回 魚池農易GO 首頁"></span
          ><span class="sr-only">首頁</span>
        </a>
      </li>

      <li>
        <a
          href="javascript:"
          v-scroll-to="'#top'"
          class="BackToTop"
          title="回到最頂[連結]"
          ><span class="icon-top"></span><span class="sr-only">top</span></a
        >
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        navigationActive: [],
        items: [
          {
            text: "首頁",
            href: "#/",
          },
          {
            text: "咖啡業者",
            active: true,
          },
        ],
        storeListAll: [],
        storeList: [],
        pagination: {
          perPage: 8,
          page: 1,
          rows: 5,
        },
        apiStore: (data) => this.userRequest.post("Default/Store", data),
      };
    },
    mounted() {
      this.init();
    },
    watch: {
      "pagination.page"() {
        this.loading = true;
        this.setPage();
      },
    },
    methods: {
      init() {
        let json = {
          TYPE: "FRONT_LIST",
          STORE_SELECT: {
            variety: {
              BlackTea: false,
              Coffee: true,
              Orchid: false,
              Mushroom: false,
              Fruits: false,
            },
          },
        };
        this.FunctionToken(this.APIGetStoreList, json);
      },
      setPage() {
        this.storeList = [];
        let count = this.pagination.perPage * this.pagination.page;
        let count1 = count - this.pagination.perPage;
        this.storeList = this.storeListAll.slice(count1, count);
        this.loading = false;
      },
      // api
      APIGetStoreList(data) {
        this.apiStore(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              console.log(jsonData);
              this.storeListAll = jsonData.outData;
              this.storeList = [];
              this.pagination.rows = jsonData.totalCount;
              this.setPage();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>
