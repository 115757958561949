<template>
  <div>
    <b-card-group deck class="m-4">
      <b-card>
        <b-card-text> 今日瀏覽人次:{{ todayCount }}人 </b-card-text>
      </b-card>
      <b-card>
        <b-card-text> 累積瀏覽人次:{{ totalCount }}人 </b-card-text>
      </b-card>
    </b-card-group>

    <apexchart
      class="m-4"
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>

    <b-card-group deck class="m-4">
      <b-card>
        <b-card-text class="text-center">
          <div>總業者</div>
          <div>{{ storeCount.storeTotalCount }}家</div></b-card-text
        >
      </b-card>
    </b-card-group>
    <b-card-group deck class="m-4">
      <b-card>
        <b-card-text class="text-center">
          <div>紅茶</div>
          <div>{{ storeCount.blackTeaCount }}家</div></b-card-text
        >
      </b-card>
      <b-card>
        <b-card-text class="text-center"
          ><div>咖啡</div>
          <div>{{ storeCount.coffeeCount }}家</div></b-card-text
        >
      </b-card>
      <b-card>
        <b-card-text class="text-center">
          <div>蘭花</div>
          <div>{{ storeCount.orchidCount }}家</div></b-card-text
        >
      </b-card>
      <b-card>
        <b-card-text class="text-center">
          <div>香菇</div>
          <div>{{ storeCount.mashroomCount }}家</div></b-card-text
        >
      </b-card>
      <b-card>
        <b-card-text class="text-center">
          <div>蔬果與其他</div>
          <div>{{ storeCount.fruitCount }}家</div></b-card-text
        >
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        todayCount: 0,
        totalCount: 0,
        dateArr: [],
        dataCountArr: [],
        series: [],
        chartOptions: {},
        storeCount: {},
        apiCount: (data) => this.userRequest.post("Default/Count", data),
      };
    },
    mounted() {
      this.init();
    },
    watch: {},
    methods: {
      init() {
        let json = {
          TYPE: "LIST",
        };
        this.FunctionToken(this.APIGetCount, json);
        let json1 = {
          TYPE: "STORECOUNT",
        };
        this.FunctionToken(this.APIGetStoreCount, json1);
      },
      setChart() {
        this.chartOptions = {
          chart: {
            height: 350,
            type: "line",
            // zoom: {
            //   enabled: false,
            // },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "瀏覽人次",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: this.dateArr,
          },
          yaxis: [
            {
              labels: {
                formatter: (value) =>
                  (typeof value === "number" && value % 1 == 0
                    ? value
                    : value.toFixed(0)) + "人",
              },
            },
          ],
        };
        this.series = [
          {
            name: "瀏覽人次",
            data: this.dataCountArr,
          },
        ];
      },
      // api
      APIGetCount(data) {
        this.apiCount(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              console.log(jsonData);
              this.todayCount = jsonData.todayCount;
              this.totalCount = jsonData.totalCount;
              this.dateArr = [];
              this.dataCountArr = [];
              if (jsonData.outData.length > 0) {
                jsonData.outData.forEach((x) => {
                  this.dateArr.push(x.date);
                  this.dataCountArr.push(x.count);
                });
              }
              this.setChart();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      APIGetStoreCount(data) {
        this.apiCount(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this.storeCount = JSON.parse(json.Data);
              console.log(this.storeCount);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>
