<template>
  <div class="Page index">
    <div class="indexBanner">
      <div class="indexLogo">
        <b-container fluid="xl">
          <b-row align-h="center">
            <b-col class="" cols="7" md="4" lg="6">
              <b-img :src="require('@/assets/imgs/logo_index.png')" fluid alt="魚池農易GO"></b-img></b-col>
          </b-row>
        </b-container>
      </div>
      <div class="indexSearchLink">
        <b-container fluid="xl">
          <b-row align-h="center" class="pb-2">
            <b-col cols="auto">
              <b-button to="ShopMap" size="lg" variant="outline-light" pill><b-icon icon="search"
                  class="mr-2"></b-icon>業者搜尋</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="indexNav">
        <b-container fluid="xl">
          <b-row align-h="center" cols="3" cols-sm="3" cols-md="3" cols-lg="5">
            <b-col>
              <b-link class="indexNavItem drop-shadow" to="BlackTea?type=1">
                <div class="indexNavTitle blackTeaTitle">
                  紅茶業者
                </div>
                <div class="indexNavPic blackTea">
                  <b-img :src="require('@/assets/imgs/icon_blacktea.png')" fluid alt="紅茶業者"></b-img>
                  <div class="navMask"></div>
                </div>
              </b-link>
            </b-col>
            <b-col>
              <b-link class="indexNavItem drop-shadow" to="Mushroom">
                <div class="indexNavTitle mushroomTitle">
                  香菇業者
                </div>
                <div class="indexNavPic mushroom">
                  <b-img :src="require('@/assets/imgs/icon_mushroom.png')" fluid alt="香菇業者"></b-img>
                  <div class="navMask"></div>
                </div>
              </b-link>
            </b-col>
            <b-col>
              <b-link class="indexNavItem drop-shadow" to="Orchid">
                <div class="indexNavTitle orchidTitle">
                  蘭花業者
                </div>
                <div class="indexNavPic orchid">
                  <b-img :src="require('@/assets/imgs/icon_orchid.png')" fluid alt="蘭花業者"></b-img>
                  <div class="navMask"></div>
                </div>
              </b-link>
            </b-col>
            <b-col>
              <b-link class="indexNavItem drop-shadow" to="Coffee">
                <div class="indexNavTitle coffeeTitle">
                  咖啡業者
                </div>
                <div class="indexNavPic coffee">
                  <b-img :src="require('@/assets/imgs/icon_coffee.png')" fluid alt="咖啡業者"></b-img>
                  <div class="navMask"></div>
                </div>
              </b-link>
            </b-col>
            <b-col>
              <b-link class="indexNavItem drop-shadow" to="Vegetable">
                <div class="indexNavTitle vegetableTitle">
                  <span class="d-block d-sm-inline-block">蔬果與</span><span class="d-block d-sm-inline-block">其他業者</span>
                </div>
                <div class="indexNavPic vegetable">
                  <b-img :src="require('@/assets/imgs/icon_vegetable.png')" fluid alt="蔬果與其他業者"></b-img>
                  <div class="navMask"></div>
                </div>
              </b-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <div class="indexshopListGroup">
      <b-container>
        <div class="indexTitle">
          <h3>農產業者</h3>
        </div>

        <div v-for="(arr1, index1) in storeArr" :key="index1">
          <!-- 單數組 -->
          <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="2" v-if="(index1 & 1) === 0">
            <b-col>
              <div class="shopListItem">
                <b-link class="drop-shadow" :to="'Shopinfo?id=' + arr1[0][0].SO_ID">
                  <div class="shopCategory">
                    <ul>
                      <li v-if="arr1[0][0].OUT_STORETYPE[0].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_blacktea.png')
                          " fluid alt="紅茶業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[3].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_mushroom.png')
                          " fluid alt="香菇業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[1].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_coffee.png')
                          " fluid alt="咖啡業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[2].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_orchid.png')
                          " fluid alt="蘭花業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[4].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_vegetable.png')
                          " fluid alt="蔬果與其他業者"></b-img>
                      </li>
                    </ul>
                  </div>
                  <div class="shopListPic shopListBig">
                    <b-img :src="arr1[0][0].MainImgUrl" :alt="arr1[0][0].ME_NAME" fluid></b-img>
                  </div>
                  <div class="shopListTitle">
                    <h3>{{ arr1[0][0].SO_NAME }}</h3>
                  </div>
                </b-link>
              </div>
            </b-col>
            <b-col>
              <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2" cols-xl="2">
                <b-col v-for="(arr2, index2) in arr1[1]" :key="index2">
                  <div class="shopListItem">
                    <b-link class="drop-shadow" :to="'Shopinfo?id=' + arr2.SO_ID">
                      <div class="shopCategory">
                        <ul>
                          <li v-if="arr2.OUT_STORETYPE[0].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_blacktea.png')
                              " fluid alt="紅茶業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[3].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_mushroom.png')
                              " fluid alt="香菇業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[1].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_coffee.png')
                              " fluid alt="咖啡業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[2].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_orchid.png')
                              " fluid alt="蘭花業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[4].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_vegetable.png')
                              " fluid alt="蔬果與其他業者"></b-img>
                          </li>
                        </ul>
                      </div>
                      <div class="shopListPic shopListSmall">
                        <b-img :src="arr2.MainImgUrl" :alt="arr2.ME_NAME" fluid></b-img>
                      </div>
                      <div class="shopListTitle">
                        <h3>{{ arr2.SO_NAME }}</h3>
                      </div>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- 雙數組 -->
          <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="2" cols-xl="2" v-else>
            <b-col order-lg="2">
              <div class="shopListItem">
                <b-link class="drop-shadow">
                  <div class="shopCategory">
                    <ul>
                      <li v-if="arr1[0][0].OUT_STORETYPE[0].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_blacktea.png')
                          " fluid alt="紅茶業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[3].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_mushroom.png')
                          " fluid alt="香菇業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[1].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_coffee.png')
                          " fluid alt="咖啡業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[2].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_orchid.png')
                          " fluid alt="蘭花業者"></b-img>
                      </li>
                      <li v-if="arr1[0][0].OUT_STORETYPE[4].status">
                        <b-img :src="require('@/assets/imgs/icon_classify_vegetable.png')
                          " fluid alt="蔬果與其他業者"></b-img>
                      </li>
                    </ul>
                  </div>
                  <div class="shopListPic shopListBig">
                    <b-img :src="arr1[0][0].MainImgUrl" :alt="arr1[0][0].ME_NAME" fluid></b-img>
                  </div>
                  <div class="shopListTitle">
                    <h3>{{ arr1[0][0].SO_NAME }}</h3>
                  </div>
                </b-link>
              </div>
            </b-col>
            <b-col>
              <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2" cols-xl="2">
                <b-col v-for="(arr2, index2) in arr1[0]" :key="index2">
                  <div class="shopListItem">
                    <b-link class="drop-shadow" :to="'Shopinfo?id=' + arr2.SO_ID">
                      <div class="shopCategory">
                        <ul>
                          <li v-if="arr2.OUT_STORETYPE[0].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_blacktea.png')
                              " fluid alt="紅茶業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[3].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_mushroom.png')
                              " fluid alt="香菇業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[1].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_coffee.png')
                              " fluid alt="咖啡業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[2].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_orchid.png')
                              " fluid alt="蘭花業者"></b-img>
                          </li>
                          <li v-if="arr2.OUT_STORETYPE[4].status">
                            <b-img :src="require('@/assets/imgs/icon_classify_vegetable.png')
                              " fluid alt="蔬果與其他業者"></b-img>
                          </li>
                        </ul>
                      </div>
                      <div class="shopListPic shopListSmall">
                        <b-img :src="arr2.MainImgUrl" :alt="arr2.ME_NAME" fluid></b-img>
                      </div>
                      <div class="shopListTitle">
                        <h3>{{ arr2.SO_NAME }}</h3>
                      </div>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <ul id="myMenu">
      <li>
        <a href="javascript:" v-scroll-to="'#top'" class="BackToTop" title="回到最頂[連結]"><span class="icon-top"></span><span
            class="sr-only">top</span></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navigationActive: [],
      items: [
        {
          text: "首頁",
          href: "#/",
        },
      ],
      imgArr: [
        {
          id: 1,
          url:
            "https://plus.unsplash.com/premium_photo-1682551838486-a10e3704e8d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
        },
        {
          id: 2,
          url:
            "https://images.unsplash.com/photo-1591534180437-507029f6ee60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",
        },
        {
          id: 3,
          url:
            "https://plus.unsplash.com/premium_photo-1682551838486-a10e3704e8d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
        },
        {
          id: 4,
          url:
            "https://plus.unsplash.com/premium_photo-1682551838486-a10e3704e8d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
        },
        {
          id: 5,
          url:
            "https://images.unsplash.com/photo-1522648485144-849409408aee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
        },
      ],
      storeArr: [],
      apiCount: (data) => this.userRequest.post("Default/Count", data),
      apiStore: (data) => this.userRequest.post("Default/Store", data),
    };
  },
  mounted() {
    this.functionInsertCount();
    this.init();
  },
  methods: {
    init() {
      this.FunctionToken(this.APIGetStoreList, {
        TYPE: "FRONT_HOME_LIST",
      });
    },
    functionInsertCount() {
      let json = {
        TYPE: "INSERT",
      };
      this.FunctionToken(this.APIInsertCount, json);
    },
    //api
    APIInsertCount(data) {
      this.apiCount(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (!json.Status) {
            console.log("計次錯誤");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // api
    APIGetStoreList(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            // console.log(jsonData)
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            let arr1_1 = [];
            let arr1_2 = [];
            let arr2_1 = [];
            let arr2_2 = [];
            let arr3_1 = [];
            let arr3_2 = [];
            let arr4_1 = [];
            let arr4_2 = [];
            for (let i = 0; i < jsonData.outData.length; i++) {
              if (i < 5) {
                if (i == 1) {
                  arr1_1.push(jsonData.outData[i]);
                } else {
                  arr1_2.push(jsonData.outData[i]);
                  if (i == 4) {
                    arr1.push(arr1_1);
                    arr1.push(arr1_2);
                  }
                }
              } else if (i < 10) {
                if (i < 9) {
                  arr2_1.push(jsonData.outData[i]);
                } else {
                  arr2_2.push(jsonData.outData[i]);
                  arr2.push(arr2_1);
                  arr2.push(arr2_2);
                }
              } else if (i < 15) {
                if (i == 10) {
                  arr3_1.push(jsonData.outData[i]);
                } else {
                  arr3_2.push(jsonData.outData[i]);
                  if (i == 14) {
                    arr3.push(arr3_1);
                    arr3.push(arr3_2);
                  }
                }
              } else if (i < 20) {
                if (i < 19) {
                  arr4_1.push(jsonData.outData[i]);
                } else {
                  arr4_2.push(jsonData.outData[i]);
                  arr4.push(arr4_1);
                  arr4.push(arr4_2);
                }
              }
            }
            this.storeArr = [];
            this.storeArr.push(arr1);
            this.storeArr.push(arr2);
            this.storeArr.push(arr3);
            this.storeArr.push(arr4);
            console.log(this.storeArr);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
