import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import mixin from './components/Mixin.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { ModalPlugin } from 'bootstrap-vue'
import { BModal, VBModal } from 'bootstrap-vue'
import './assets/layout.scss'
import '../public/assets/css/style.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueApexCharts from 'vue-apexcharts'
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import VueScrollTo from 'vue-scrollto'
import VueCarousel from 'vue-carousel';
import { IconsPlugin } from 'bootstrap-vue'
import ECharts from "vue-echarts";
Vue.component('v-chart', ECharts) //全局注册

Vue.use(IconsPlugin)
Vue.use(BootstrapVue)
Vue.use(ModalPlugin)
Vue.use(VueCarousel);
Vue.component('b-modal', BModal)
Vue.directive('b-modal', VBModal)
Vue.component('apexchart', VueApexCharts)
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-icon', LIcon);
Vue.mixin(mixin)
Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios;
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

// 設定預設 icon
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

axios.defaults.baseURL = '/api'
Vue.config.productionTip = false

new Vue({
  router,
  created() {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
