<template>
  <div>
    <DeleteModal :deleteModal="deleteModal" @init="init()"></DeleteModal>
    <div v-if="!detail">
      <b-container class="py-5">
        <b-row class="pb-2">
          <b-col cols="10">
            <b-row>
              <b-col>
                <b-form-input placeholder="請輸入經營名稱" v-model="keywords" />
              </b-col>
              <b-col> <b-button @click="init">搜尋</b-button></b-col>
            </b-row>
          </b-col>
          <b-col><b-button @click="_go('?id=null')">新增</b-button></b-col></b-row>
        <b-table-simple class="table table-bordered">
          <b-thead class="thead-dark">
            <b-tr>
              <b-th>經營名稱</b-th>
              <b-th>建檔日期</b-th>
              <b-th>修改</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in storeList" :key="item.SO_ID">
              <b-td>{{ item.SO_NAME }}</b-td>
              <b-td>{{ dateFormat(item.SO_CREATETIME) }}</b-td>
              <b-td>
                <b-button class="mr-5" variant="info" @click="_go('?id=' + item.SO_ID)">編輯</b-button>
                <b-button @click="FunctionDeleteStore(item)">刪除</b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="page">
          <b-pagination class="pagination light-theme simple-pagination" v-model="pagination.page"
            :total-rows="pagination.rows" :per-page="pagination.perPage" aria-controls="my-table"></b-pagination>
        </div>
      </b-container>
    </div>
    <div v-else>
      <b-container>
        <div class="btare">
          <div class="backrul">
            <b-button @click="_go('/admin/store')">返回列表</b-button>
          </div>
        </div>
        <b-row>
          <b-col cols="6">
            <label>經營名稱</label>
            <b-form-input v-model="storeDetail.SO_NAME" placeholder="請輸入經營名稱" />
          </b-col>

          <b-col cols="3">
            <label>經度</label>
            <b-form-input type="number" v-model="storeDetail.SO_XAXIS" placeholder="請輸入經度 EX:120.919311" />
          </b-col>
          <b-col cols="3">
            <label>緯度</label>
            <b-form-input type="number" v-model="storeDetail.SO_YAXIS" placeholder="請輸入緯度 EX:23.860191" />
          </b-col>
        </b-row>
        <label class="ml-3">地址</label>
        <b-row>
          <b-col cols="3">
            <b-form-input :disabled="true" value="南投縣" />
          </b-col>
          <b-col cols="3">
            <b-form-input :disabled="true" value="魚池鄉" />
          </b-col>
          <b-col cols="6">
            <b-form-input v-model="storeDetail.SO_ADDRESS" placeholder="請輸入地址" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="10">
            <label>Google Map導航</label>
            <b-form-input v-model="storeDetail.SO_GOOGLEMAP" placeholder="請輸入地圖URL" />
          </b-col>
          <b-col cols="2">
            <b-button class="mt-4" @click="
              exampleModel = {
                show: true,
                iframe: false,
              }
              ">範例</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="10">
            <label>Google Map嵌入地圖</label>
            <b-form-input v-model="storeDetail.SO_GOOGLEMAPIFRAME" placeholder="請輸入嵌入地圖URL" />
          </b-col>
          <b-col cols="2">
            <b-button class="mt-4" @click="
              exampleModel = {
                show: true,
                iframe: true,
              }
              ">範例</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label>營業時間</label>
            <b-row v-for="(item, index) in storeDetail.timeArr" :key="index">
              <b-col cols="2">
                <div>{{ item.title }}</div>
              </b-col>
              <b-col cols="10">
                <b-form-input v-if="item.title != '預約制'" v-model="item.value" placeholder="請輸入時段" />
                <b-form-input v-if="item.title == '預約制'" v-model="item.value" placeholder="請輸入預約方式" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label>農產品種</label>
            <b-row v-for="(item, index) in storeDetail.SO_PRODUCTTYPE" :key="index">
              <b-col>
                <b-row>
                  <b-col cols="5">
                    <b-row>
                      <b-col>
                        <b-form-checkbox v-model="item.status">
                          {{ item.title }}
                        </b-form-checkbox>
                      </b-col>
                      <b-col>
                        <b-form-input v-if="item.title == '其他' && item.status" v-model="item.value"
                          placeholder="請輸入農產品種" />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="7">
                    <b-row v-if="item.status">
                      <label>種植面積/年產量</label>
                      <b-col>
                        <b-form-input v-model="item.detail" placeholder="請輸入種植面積/年產量" /></b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <label>聯繫方式</label>
            <b-row v-for="(item, index) in storeDetail.SO_CONTACT" :key="index">
              <b-col>
                <b-form-checkbox v-model="item.status">
                  {{ item.title }}
                </b-form-checkbox></b-col>
              <b-col>
                <b-form-input v-if="item.status" v-model="item.value" placeholder="請輸入聯繫方式" /></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label>付款方式</label>
            <b-row v-for="(item, index) in storeDetail.SO_PAY" :key="index">
              <b-col>
                <b-form-checkbox v-model="item.status">
                  {{ item.title }}
                </b-form-checkbox></b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <label>線上賣場</label>
            <b-form-group>
              <b-form-radio v-model="storeDetail.SO_ONLINESALE" :value="false" name="onlineSale-radios">無</b-form-radio>
              <b-form-radio v-model="storeDetail.SO_ONLINESALE" :value="true" name="onlineSale-radios">有</b-form-radio>
            </b-form-group>
            <b-form-input v-if="storeDetail.SO_ONLINESALE" v-model="storeDetail.SO_ONLINESALEURL" placeholder="請輸入網址" />
          </b-col></b-row>
        <b-row>
          <b-col cols="6">
            <label>品種(前臺分類用)</label>
            <b-row v-for="(item, index) in storeDetail.SO_STORETYPE" :key="index">
              <b-col>
                <b-form-checkbox v-model="item.status">
                  {{ item.title }}
                </b-form-checkbox></b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <label>服務類型</label>
            <b-row v-for="(item, index) in storeDetail.serviceTypeArr" :key="index">
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-checkbox v-model="item.status">
                      {{ item.title }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-input v-if="item.title == '其他' && item.status" v-model="item.value" placeholder="請輸入服務類型" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <label>特殊認證</label>
            <b-row v-for="(item, index) in storeDetail.SO_PERMISSION" :key="index">
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-checkbox v-model="item.status">
                      {{ item.title }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-input v-if="item.title == '有機認證' && item.status" v-model="item.value"
                      placeholder="請輸入有機證書字號" />
                    <b-form-input v-if="item.title == '友善栽培' && item.status" v-model="item.value" placeholder="請輸入友善字號" />
                    <b-form-input v-if="item.title == '其他' && item.status" v-model="item.value" placeholder="請輸入特殊認證" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <label>其他服務</label>
            <b-row v-for="(item, index) in storeDetail.SO_OTSERVICE" :key="index">
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-checkbox v-model="item.status">
                      {{ item.title }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-input v-if="item.title == '其他' && item.status" v-model="item.value" placeholder="請輸入其他服務" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label>經營理念</label>
            <b-form-textarea rows="8" max-rows="6" v-model="storeDetail.SO_IDEA" placeholder="請輸入經營理念	"></b-form-textarea>
          </b-col>
          <b-col cols="6">
            <label>附近景點</label>
            <b-form-input v-model="storeDetail.SO_VIEW" placeholder="請輸入附近景點	" />
            <label>其他特殊產品</label>
            <b-form-input v-model="storeDetail.SO_SPECIALPRODUCT" placeholder="請輸入其他特殊產品" />
            <label>其他特殊事蹟</label>
            <b-form-input v-model="storeDetail.SO_SPECIALMATTER" placeholder="請輸入其他特殊事蹟	" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <label>主圖</label>
                <b-row>
                  <b-col cols="9">
                    <b-form-file v-model="mainImg" :state="Boolean(mainImg)" placeholder="請上傳主圖"></b-form-file>
                  </b-col>
                  <b-col cols="3">
                    <b-button v-if="mainImg != null" @click="FunctionUploadImage(mainImg, '0')">確定</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col v-if="image.mainImg == null">
                    <div>無主圖</div>
                  </b-col>
                  <b-col cols="9" v-if="image.mainImg != null">
                    <img :src="image.mainImg.url" alt="主圖" />
                  </b-col>
                  <b-col cols="3" v-if="image.mainImg != null">
                    <b-button @click="FunctionDeleteImage(image.mainImg)">刪除</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <label>附圖</label>
                <b-row>
                  <b-col cols="9">
                    <b-form-file v-model="otherImg" :state="Boolean(otherImg)" placeholder="請上傳附圖"></b-form-file>
                  </b-col>
                  <b-col cols="3">
                    <b-button v-if="otherImg != null" @click="FunctionUploadImage(otherImg, '1')">確定</b-button>
                  </b-col>
                </b-row>
                <b-row v-for="item in image.imgArr" :key="item.ME_ID">
                  <b-col class="mb-3" cols="9">
                    <img :src="item.url" alt="附圖" />
                  </b-col>
                  <b-col cols="3">
                    <b-button @click="FunctionDeleteImage(item)">刪除</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col v-if="image.imgArr.length == 0">
                    <div>無附圖</div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div>
          <b-button @click="FunctionSaveStore">儲存</b-button>
        </div>
      </b-container>
    </div>
    <b-modal v-model="exampleModel.show" size="xl" title="範例影片" ok-only>
      <template #modal-footer>
        <button v-b-modal.modal-close_visit class="btn btn-success btn-sm m-1"
          @click="exampleModel.show = !exampleModel.show">
          確定
        </button>
      </template>

      <b-embed type="video" controls>
        <source v-if="!exampleModel.iframe" src="@/assets/imgs/googleMap範例.mp4" type="video/mp4" />
        <source v-else src="@/assets/imgs/googleMapIframe範例.mp4" type="video/mp4" />
      </b-embed>
    </b-modal>
  </div>
</template>

<script>
import DeleteModal from "../../components/admin/deleteModal.vue";
export default {
  components: {
    DeleteModal,
  },
  data() {
    return {
      keywords: null,
      exampleModel: {
        show: false,
        mp4Path: null,
      },
      formData: new FormData(),
      detail: false,
      storeList: [],
      storeDetail: {},
      mainImg: null,
      otherImg: null,
      pagination: {
        perPage: 6,
        page: 1,
        rows: 5,
      },
      deleteModal: {
        id: null,
        show: false,
        type: "store",
      },
      image: {
        mainImg: null,
        imgArr: [],
      },
      apiStore: (data) => this.userRequest.post("Default/Store", data),
      apiMedia: (data) => this.userRequest.post("Default/Media", data),
      apiMedia2: (data) => this.userRequest.post("Default/Media2", data),
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    init() {
      this.id = this.$route.query.id;
      if (this.id == undefined) {
        this.detail = false;
        let json = {
          TYPE: "LIST",
          STORE_SELECT: {
            keywords: this.keywords,
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
          },
        };
        this.FunctionToken(this.APIGetStoreList, json);
      } else if (this.id == "null") {
        this.detail = true;
        this.id = "SO_" + Date.now();
        this.image = {
          mainImg: null,
          imgArr: [],
        };
        this.storeDetail = {
          timeArr: [
            { title: "星期一", value: null },
            { title: "星期二", value: null },
            { title: "星期三", value: null },
            { title: "星期四", value: null },
            { title: "星期五", value: null },
            { title: "星期六", value: null },
            { title: "星期日", value: null },
            { title: "預約制", value: null },
          ],
          SO_CONTACT: [
            { status: false, title: "電話", value: null },
            { status: false, title: "Line", value: null },
            { status: false, title: "Facebook", value: null },
            { status: false, title: "Instagram", value: null },
            { status: false, title: "官方網站", value: null },
          ],
          SO_PAY: [
            { status: false, title: "現金" },
            { status: false, title: "轉帳" },
            { status: false, title: "貨到付款" },
            { status: false, title: "信用卡" },
            { status: false, title: "行動支付" },
          ],
          SO_STORETYPE: [
            { status: false, title: "紅茶" },
            { status: false, title: "咖啡" },
            { status: false, title: "蘭花" },
            { status: false, title: "香菇" },
            { status: false, title: "蔬果與其他" },
          ],
          SO_PRODUCTTYPE: [
            { status: false, title: "紅玉", detail: null },
            { status: false, title: "紅韻", detail: null },
            {
              status: false,
              title: "阿薩姆",
              detail: null,
            },
            { status: false, title: "山茶", detail: null },
            { status: false, title: "蘭花", detail: null },
            { status: false, title: "香菇", detail: null },
            { status: false, title: "絲瓜", detail: null },
            { status: false, title: "咖啡", detail: null },
            {
              status: false,
              title: "其他",
              value: null,
              detail: null,
            },
          ],
          serviceTypeArr: [
            { status: false, title: "零售" },
            { status: false, title: "批發" },
            { status: false, title: "輕鬆坐下來" },
            { status: false, title: "體驗服務" },
            { status: false, title: "住宿" },
            { status: false, title: "其他", value: null },
          ],
          SO_PERMISSION: [
            { status: false, title: "有機認證", value: null },
            { status: false, title: "友善栽培", value: null },
            { status: false, title: "產銷履歷" },
            { status: false, title: "日月潭紅茶產地標章" },
            { status: false, title: "其他", value: null },
          ],
          SO_OTSERVICE: [
            { status: false, title: "車位" },
            { status: false, title: "WiFi" },
            { status: false, title: "打卡秘境" },
            { status: false, title: "其他", value: null },
          ],
          SO_ONLINESALE: false,
          mainImg: { url: null },
          imgArr: [],
        };
      } else {
        this.detail = true;
        this.storeDetail = {};
        let json = {
          TYPE: "SELECT",
          STORE_SELECT: {
            SO_ID: this.id,
          },
        };
        this.FunctionToken(this.APIGetStoreDetail, json);
      }
    },
    initImage() {
      let json = {
        TYPE: "LIST",
        MEDIA_SELECT: {
          ME_SOID: this.id,
        },
      };
      this.FunctionToken(this.APIGetImageList, json);
    },
    FunctionDeleteStore(item) {
      this.deleteModal.show = true;
      this.deleteModal.id = item.SO_ID;
      this.deleteModal.title = item.SO_NAME;
    },
    FunctionDeleteImage(item) {
      let json = {
        TYPE: "DELETE",
        MEDIA_DELETE: {
          ME_ID: item.ME_ID,
        },
      };
      console.log(json);
      this.FunctionToken(this.APIDeleteImage, json);
    },
    FunctionSaveStore() {
      if (this.image.mainImg == null) {
        alert("請上傳主圖");
      }
      // else if (
      //   this.storeDetail.SO_XAXIS == null ||
      //   this.storeDetail.SO_YAXIS == null
      // ) {
      //   alert("請輸入經緯度");
      // }
      else {
        if (this.$route.query.id == "null") {
          let json = {
            TYPE: "INSERT",
            STORE_INSERT: {
              SO_ID: this.id,
              SO_NAME: this.storeDetail.SO_NAME,
              SO_XAXIS: this.storeDetail.SO_XAXIS,
              SO_YAXIS: this.storeDetail.SO_YAXIS,
              SO_ADDRESS: this.storeDetail.SO_ADDRESS,
              SO_GOOGLEMAP: this.storeDetail.SO_GOOGLEMAP,
              SO_GOOGLEMAPIFRAME: this.storeDetail.SO_GOOGLEMAPIFRAME,
              SO_CONTACT: this.storeDetail.SO_CONTACT,
              SO_PAY: this.storeDetail.SO_PAY,
              SO_ONLINESALE: this.storeDetail.SO_ONLINESALE,
              SO_ONLINESALEURL: this.storeDetail.SO_ONLINESALEURL,
              SO_STORETYPE: this.storeDetail.SO_STORETYPE,
              SO_PRODUCTTYPE: this.storeDetail.SO_PRODUCTTYPE,
              SO_PERMISSION: this.storeDetail.SO_PERMISSION,
              SO_OTSERVICE: this.storeDetail.SO_OTSERVICE,
              SO_IDEA: this.storeDetail.SO_IDEA,
              SO_VIEW: this.storeDetail.SO_VIEW,
              SO_SPECIALPRODUCT: this.storeDetail.SO_SPECIALPRODUCT,
              SO_SPECIALMATTER: this.storeDetail.SO_SPECIALMATTER,
              OPENHOURS_INSERT: {
                OH_SOID: this.id,
                OH_MON: this.storeDetail.timeArr[0].value,
                OH_TUE: this.storeDetail.timeArr[1].value,
                OH_WED: this.storeDetail.timeArr[2].value,
                OH_THU: this.storeDetail.timeArr[3].value,
                OH_FRI: this.storeDetail.timeArr[4].value,
                OH_SAT: this.storeDetail.timeArr[5].value,
                OH_SUN: this.storeDetail.timeArr[6].value,
                OH_APPOINTMENT: this.storeDetail.timeArr[7].value,
              },
              SERVICETYPE_INSERT: {
                ST_SOID: this.id,
                ST_SALE: this.storeDetail.serviceTypeArr[0].status,
                ST_WHOLESALE: this.storeDetail.serviceTypeArr[1].status,
                ST_SEAT: this.storeDetail.serviceTypeArr[2].status,
                ST_EXPERIENCE: this.storeDetail.serviceTypeArr[3].status,
                ST_STAY: this.storeDetail.serviceTypeArr[4].status,
                ST_ANOTHER: this.storeDetail.serviceTypeArr[5].status,
                ST_ANOTHERDETAIL: this.storeDetail.serviceTypeArr[5].value,
              },
            },
          };
          this.FunctionToken(this.APIInsertStore, json);
        } else {
          let json = {
            TYPE: "UPDATE",
            STORE_UPDATE: {
              SO_ID: this.id,
              SO_NAME: this.storeDetail.SO_NAME,
              SO_XAXIS: this.storeDetail.SO_XAXIS,
              SO_YAXIS: this.storeDetail.SO_YAXIS,
              SO_ADDRESS: this.storeDetail.SO_ADDRESS,
              SO_GOOGLEMAP: this.storeDetail.SO_GOOGLEMAP,
              SO_GOOGLEMAPIFRAME: this.storeDetail.SO_GOOGLEMAPIFRAME,
              SO_CONTACT: this.storeDetail.SO_CONTACT,
              SO_PAY: this.storeDetail.SO_PAY,
              SO_ONLINESALE: this.storeDetail.SO_ONLINESALE,
              SO_ONLINESALEURL: this.storeDetail.SO_ONLINESALEURL,
              SO_STORETYPE: this.storeDetail.SO_STORETYPE,
              SO_PRODUCTTYPE: this.storeDetail.SO_PRODUCTTYPE,
              SO_PERMISSION: this.storeDetail.SO_PERMISSION,
              SO_OTSERVICE: this.storeDetail.SO_OTSERVICE,
              SO_IDEA: this.storeDetail.SO_IDEA,
              SO_VIEW: this.storeDetail.SO_VIEW,
              SO_SPECIALPRODUCT: this.storeDetail.SO_SPECIALPRODUCT,
              SO_SPECIALMATTER: this.storeDetail.SO_SPECIALMATTER,
              OPENHOURS_UPDATE: {
                OH_SOID: this.storeDetail.SO_ID,
                OH_MON: this.storeDetail.timeArr[0].value,
                OH_TUE: this.storeDetail.timeArr[1].value,
                OH_WED: this.storeDetail.timeArr[2].value,
                OH_THU: this.storeDetail.timeArr[3].value,
                OH_FRI: this.storeDetail.timeArr[4].value,
                OH_SAT: this.storeDetail.timeArr[5].value,
                OH_SUN: this.storeDetail.timeArr[6].value,
                OH_APPOINTMENT: this.storeDetail.timeArr[7].value,
              },
              SERVICETYPE_UPDATE: {
                ST_SOID: this.storeDetail.SO_ID,
                ST_SALE: this.storeDetail.serviceTypeArr[0].status,
                ST_WHOLESALE: this.storeDetail.serviceTypeArr[1].status,
                ST_SEAT: this.storeDetail.serviceTypeArr[2].status,
                ST_EXPERIENCE: this.storeDetail.serviceTypeArr[3].status,
                ST_STAY: this.storeDetail.serviceTypeArr[4].status,
                ST_ANOTHER: this.storeDetail.serviceTypeArr[5].status,
                ST_ANOTHERDETAIL: this.storeDetail.serviceTypeArr[5].value,
              },
            },
          };
          console.log(json);
          this.FunctionToken(this.APIUpdateStore, json);
        }
      }
    },
    FunctionUploadImage(file, type) {
      this.formData.append("TYPE", "INSERT");
      this.formData.append("ME_SOID", this.id);
      this.formData.append("ME_NAME", file.name);
      this.formData.append("httpPostedFile", file);
      this.formData.append("ME_TYPE", type);
      this.FunctionToken(this.APIInsertImage, {});
    },
    FunctionImageToastShow() {
      this.$bvToast.toast("新增成功", {
        title: "提示",
        solid: true,
      });
      this.mainImg = null;
      this.otherImg = null;
      this.initImage();
    },
    // api
    APIGetStoreList(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            console.log(jsonData);
            this.storeList = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    APIGetStoreDetail(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            console.log(jsonData.serviceTypeArr);
            this.storeDetail = jsonData;
            this.storeDetail.SO_CONTACT = JSON.parse(jsonData.SO_CONTACT);
            this.storeDetail.SO_OTSERVICE = JSON.parse(jsonData.SO_OTSERVICE);
            this.storeDetail.SO_PAY = JSON.parse(jsonData.SO_PAY);
            this.storeDetail.SO_PERMISSION = JSON.parse(
              jsonData.SO_PERMISSION
            );
            this.storeDetail.SO_PRODUCTTYPE = JSON.parse(
              jsonData.SO_PRODUCTTYPE
            );
            this.storeDetail.SO_STORETYPE = JSON.parse(jsonData.SO_STORETYPE);
            this.initImage();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    APIInsertStore(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log(json);
          if (json.Status) {
            this.$bvToast.toast("新增成功", {
              title: "提示",
              solid: true,
            });
            this._go("/admin/store");
          } else {
            alert(JSON.parse(json.Data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    APIUpdateStore(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          console.log(json);
          if (json.Status) {
            this.$bvToast.toast("更新成功", {
              title: "提示",
              solid: true,
            });
            this._go("/admin/store");
          } else {
            alert(JSON.parse(json.Data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    APIInsertImage(data) {
      this.formData.append("Token", data.Token);
      this.apiMedia2(this.formData)
        .then((res) => {
          let json = JSON.parse(res.data);
          this.formData = new FormData();
          if (json.Status) {
            this.FunctionImageToastShow();
          } else {
            alert(JSON.parse(json.Data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    APIDeleteImage(data) {
      this.apiMedia(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.$bvToast.toast("刪除成功", {
              title: "提示",
              solid: true,
            });
            this.initImage();
          } else {
            alert(JSON.parse(json.Data));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    APIGetImageList(data) {
      this.apiMedia(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.image.mainImg = jsonData.mainImg;
            this.image.imgArr = jsonData.imgArr;
          } else {
            console.log(json);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
