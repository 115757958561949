<template>
  <div class="Page inner">
    <section class="shopHeader" :style="sectionStyle">
      <b-container class="shopinfoAll drop-shadow">
        <b-row align-h="center" align-v="center">
          <b-col cols="auto">
            <div class="shopCategory">
              <ul>
                <li v-if="detail.storeType[0].status">
                  <b-img :src="require('@/assets/imgs/icon_classify_blacktea.png')" fluid alt="紅茶業者" />
                </li>
                <li v-if="detail.storeType[3].status">
                  <b-img :src="require('@/assets/imgs/icon_classify_mushroom.png')" fluid alt="香菇業者" />
                </li>
                <li v-if="detail.storeType[1].status">
                  <b-img :src="require('@/assets/imgs/icon_classify_coffee.png')" fluid alt="咖啡業者" />
                </li>
                <li v-if="detail.storeType[2].status">
                  <b-img :src="require('@/assets/imgs/icon_classify_orchid.png')" fluid alt="蘭花業者" />
                </li>
                <li v-if="detail.storeType[4].status">
                  <b-img :src="require('@/assets/imgs/icon_classify_vegetable.png')" fluid alt="蔬果與其他業者" />
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
        <b-row align-h="center" align-v="center">
          <b-col lg="8">
            <h2 class="shopMaintitle">
              {{ detail.name }}
            </h2>
            <div class="shopinfoGroup py-1">
              <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="2">
                <b-col>
                  <div class="shopinfoList">
                    <div><i class="fa fa-map-marker" aria-hidden="true" /></div>
                    <div>{{ detail.address }}</div>
                  </div>
                  <div class="shopinfoList" v-if="detail.phone.status">
                    <div><i class="fa fa-phone" aria-hidden="true" /></div>
                    <div>{{ detail.phone.value }}</div>
                  </div>
                  <div class="shopinfoList" v-if="detail.line.status">
                    <div><span class="icon-line" /></div>
                    <div>{{ detail.line.value }}</div>
                  </div>
                  <div class="shopinfoList">
                    <div><span class="icon-pay" /></div>
                    <div>{{ detail.pay }}</div>
                  </div>
                </b-col>
                <b-col>
                  <div class="shopinfoList">
                    <div><i class="fa fa-clock-o" aria-hidden="true" /></div>
                    <ul>
                      <li v-for="(item, index) in detail.time" :key="index">
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="shoplinkGroup py-1 py-md-2">
              <b-row class="justify-content-center" cols="4" cols-sm="6" cols-md="6" cols-lg="6">
                <b-col v-if="detail.contact[2].status" class="text-center">
                  <b-link :href="detail.contact[2].value" target="_blank" v-b-tooltip.hover title="連結到facebook">
                    <b-img :src="require('@/assets/imgs/shopinfo_icon_fb.png')" fluid alt="連結到facebook" />
                  </b-link>
                </b-col>
                <b-col v-if="detail.contact[3].status" class="text-center">
                  <b-link :href="detail.contact[3].value" target="_blank" v-b-tooltip.hover title="連結到IG">
                    <b-img :src="require('@/assets/imgs/shopinfo_icon_ig.png')" fluid alt="連結到IG" />
                  </b-link>
                </b-col>
                <b-col v-if="detail.contact[4].status" class="text-center">
                  <b-link :href="detail.contact[4].value" target="_blank" v-b-tooltip.hover title="連結到官方網站">
                    <b-img :src="require('@/assets/imgs/shopinfo_icon_website.png')" fluid alt="連結到官方網站" />
                  </b-link>
                </b-col>
                <b-col v-if="detail.onlineSale" class="text-center">
                  <b-link :href="detail.onlineUrl" target="_blank" v-b-tooltip.hover title="連結到購物車">
                    <b-img :src="require('@/assets/imgs/shopinfo_icon_shopLink.png')" fluid alt="連結到購物車" />
                  </b-link>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-center">
          <b-col cols="auto" class="align-self-end pt-2">
            <b-button v-scroll-to="'#main'" size="sm" variant="outline-light" pill class="px-3">更多資訊</b-button>
          </b-col>
        </b-row>
      </b-container>
      <div class="mask"></div>
    </section>

    <section class="shopIntro">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col lg="8">
            <div class="shopIntroText">
              <p>
                {{ detail.content }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="shopAlbum">
      <carousel :autoplay="true">
        <slide v-for="(item, index) in imgArr" :key="index">
          <img :src="item.url" :alt="item.ME_FILE" />
        </slide>
      </carousel>
    </section>

    <a name="main" id="main"><span class="sr-only">main</span></a>

    <section class="ShopMainInfo">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col lg="8">
            <div class="shopSubTitle">產品資訊</div>
            <div class="mainInfoGroup">
              <div class="mainInfoItem">
                <div class="mainInfoItemTitle">農產品種</div>
                <div class="mainInfoItemContent">
                  {{ detail.productType }}
                </div>
              </div>
              <div class="mainInfoItem">
                <div class="mainInfoItemTitle">種植面積/年產量</div>
                <div class="mainInfoItemContent">
                  {{ detail.productArea }}
                </div>
              </div>
              <div class="mainInfoItem" v-if="detail.permission != ''">
                <div class="mainInfoItemTitle">特殊認證</div>
                <div class="mainInfoItemContent">
                  {{ detail.permission }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section>
      <b-img :src="require('@/assets/imgs/wave_top.png')" fluid />
    </section>

    <section class="ShopMainInfo product">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col lg="8">
            <div class="shopSubTitle">服務資訊</div>
            <div class="mainInfoGroup">
              <div class="mainInfoItem">
                <div class="mainInfoItemTitle">服務類型</div>
                <div class="mainInfoItemContent">
                  {{ detail.serviceType }}
                </div>
              </div>
              <div class="mainInfoItem" v-if="detail.otService != ''">
                <div class="mainInfoItemTitle">其他服務</div>
                <div class="mainInfoItemContent">
                  {{ detail.otService }}
                </div>
              </div>
              <div class="mainInfoItem" v-if="detail.view != null">
                <div class="mainInfoItemTitle">附近景點</div>
                <div class="mainInfoItemContent">{{ detail.view }}</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section>
      <b-img :src="require('@/assets/imgs/wave_bottom.png')" fluid />
    </section>

    <section class="ShopGoogleMap" v-if="detail.mapIframe != null">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col lg="8">
            <div class="iframe-rwd" v-html="detail.mapIframe"></div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <ul id="myMenu">
      <li>
        <a href="/" tooltip="首頁" flow="left"><span class="icon-home3" title="返回 魚池農易GO 首頁" /><span
            class="sr-only">首頁</span>
        </a>
      </li>

      <li>
        <a href="javascript:" v-scroll-to="'#top'" class="BackToTop" title="回到最頂[連結]"><span class="icon-top" /><span
            class="sr-only">top</span></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navigationActive: [],
      items: [
        {
          text: "首頁",
          href: "#/",
        },
      ],
      detail: { storeType: [{ status: false }, { status: false }, { status: false }, { status: false }, { status: false },] },
      storeDetail: {},
      sectionStyle: "background-image: url('');",
      imgArr: [],
      apiStore: (data) => this.userRequest.post("Default/Store", data),
      apiMedia: (data) => this.userRequest.post("Default/Media", data),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let json = {
        TYPE: "SELECT",
        STORE_SELECT: {
          SO_ID: this.$route.query.id,
        },
      };
      this.FunctionToken(this.APIGetStoreDetail, json);
    },
    //api
    APIGetStoreDetail(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            jsonData.SO_CONTACT = JSON.parse(jsonData.SO_CONTACT);
            this.detail = {
              name: jsonData.SO_NAME,
              address: "南投縣魚池鄉" + jsonData.SO_ADDRESS,
              phone: jsonData.SO_CONTACT[0],
              line: jsonData.SO_CONTACT[1],
              contact: jsonData.SO_CONTACT,
              content: jsonData.SO_IDEA,
              pay: null,
              time: null,
              productType: null,
              productArea: null,
              permission: JSON.parse(jsonData.SO_PERMISSION),
              serviceType: null,
              otService: null,
              view: jsonData.SO_VIEW,
              mapIframe: jsonData.SO_GOOGLEMAPIFRAME,
              onlineSale: jsonData.SO_ONLINESALE,
              onlineUrl: jsonData.SO_ONLINESALEURL,
              storeType: JSON.parse(jsonData.SO_STORETYPE),
            };
            //付款方式
            let payArr = JSON.parse(jsonData.SO_PAY);
            this.detail.pay = [];
            payArr.forEach((x) => {
              if (x.status) this.detail.pay.push(x.title);
            });
            this.detail.pay = this.detail.pay.toString();
            //營業時間
            let timeArr = [];
            jsonData.timeArr.forEach((x) => {
              if (x.value != null) timeArr.push(x.title + ":" + x.value);
            });
            this.detail.time = timeArr;
            //農產品種、種植面積/年產量
            this.detail.productType = [];
            this.detail.productArea = [];
            let productType = JSON.parse(jsonData.SO_PRODUCTTYPE);
            productType.forEach((x) => {
              if (x.status) {
                if (x.title != "其他") {
                  this.detail.productType.push(x.title);
                  if (x.detail != null) {
                    this.detail.productArea.push(x.title + x.detail);
                  }
                } else {
                  this.detail.productType.push(x.value);
                  if (x.detail != null) {
                    this.detail.productArea.push(x.title + x.detail);
                  }
                }
              }
            });
            this.detail.productType = this.detail.productType.toString();
            this.detail.productArea = this.detail.productArea.toString();
            //特殊認證
            let permissionArr = [];
            JSON.parse(jsonData.SO_PERMISSION).forEach((x) => {
              if (x.status) permissionArr.push(x.title);
            });
            this.detail.permission = permissionArr.toString();
            //服務類型
            let serviceTypeArr = [];
            jsonData.serviceTypeArr.forEach((x) => {
              if (x.status) serviceTypeArr.push(x.title);
            });
            this.detail.serviceType = serviceTypeArr.toString();
            //其他服務
            let otherServiceArr = [];
            JSON.parse(jsonData.SO_OTSERVICE).forEach((x) => {
              if (x.status) otherServiceArr.push(x.title);
            });
            this.detail.otService = otherServiceArr.toString();
            this.initImage();
            console.log(this.detail.storeType);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initImage() {
      let json = {
        TYPE: "LIST",
        MEDIA_SELECT: {
          ME_SOID: this.$route.query.id,
        },
      };
      this.FunctionToken(this.APIGetImageList, json);
    },
    APIGetImageList(data) {
      this.apiMedia(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.sectionStyle =
              "background-image: url('" + jsonData.mainImg.url + "');";
            this.imgArr = jsonData.imgArr;
          } else {
            console.log(json);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
