<template>
  <div class="shopMap">
    <div class="shopMapSideBar" :class="{ open: sideBarShow }">
      <div class="showhideIcon" @click="sideBarShow = !sideBarShow">
        <i class="fa" :class="sideBarShow ? 'fa-chevron-left' : 'fa-chevron-right'" aria-hidden="true"></i>
      </div>
      <div class="SideBarContent">
        <div class="searchBox py-3">
          <b-form inline>
            <b-form-input class="mb-2 mr-sm-2 mb-xs-2 mb-sm-0" v-model="search.keywords"
              placeholder="請輸入關鍵字"></b-form-input>
            <b-button class="mr-2" size="sm" pill @click="searchItemsShow = !searchItemsShow">篩選</b-button>
            <b-button size="sm" pill @click="init()">搜尋</b-button>
          </b-form>
        </div>
        <div v-if="searchItemsShow">
          <div class="searchItemBox">
            <div class="searchItem">
              <b-form inline>
                <label class="searchItemTitle">營業時間</label>
                <div v-for="item in search.time" :key="item.title">
                  <b-form-checkbox v-model="item.status" @change="init()">
                    {{ item.title }}
                  </b-form-checkbox>
                </div>
              </b-form>
            </div>

            <div class="searchItem">
              <b-form inline>
                <label class="searchItemTitle">付款方式</label>
                <div v-for="item in search.pay" :key="item.title">
                  <b-form-checkbox v-model="item.status" @change="init()">
                    {{ item.title }}
                  </b-form-checkbox>
                </div>
              </b-form>
            </div>

            <div class="searchItem">
              <b-form inline>
                <label class="searchItemTitle">產業類別</label>
                <div v-for="item in search.variety" :key="item.title">
                  <b-form-checkbox v-model="item.status" @change="init()">
                    {{ item.title }}
                  </b-form-checkbox>
                </div>
              </b-form>
            </div>

            <div class="searchItem" v-if="search.variety[0].status">
              <b-form inline>
                <label class="searchItemTitle">紅茶品種</label>
                <div v-for="item in search.BlackTeaType" :key="item.title">
                  <b-form-checkbox v-model="item.status" @change="init()">
                    {{ item.title }}
                  </b-form-checkbox>
                </div>
              </b-form>
            </div>

            <div class="searchItem">
              <b-form inline>
                <label class="searchItemTitle">服務類型</label>
                <div v-for="item in search.serviceType" :key="item.title">
                  <b-form-checkbox v-model="item.status" @change="init()">
                    {{ item.title }}
                  </b-form-checkbox>
                </div>
              </b-form>
            </div>
            <div class="searchItem">
              <b-form inline>
                <label class="searchItemTitle">特殊認證</label>
                <div v-for="item in search.permission" :key="item.title">
                  <b-form-checkbox v-model="item.status" @change="init()">
                    {{ item.title }}
                  </b-form-checkbox>
                </div>
              </b-form>
            </div>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <div v-else>
          <div class="shopInfoGroup">
            <a v-for="item in storeList" :key="item.id" href="javascript:" @click="setPosition(item)"
              class="shopInfoItem">
              <b-row>
                <b-col cols="5">
                  <div class="picWrap">
                    <b-img :src="item.imgUrl" fluid :alt="item.imgName"></b-img>
                  </div>
                </b-col>
                <b-col cols="7" align-self="center">
                  <div class="ContentGroup">
                    <div class="shopInfoItemTitle">{{ item.name }}</div>
                    <div class="shopInfoItemContent">
                      {{ item.address }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </a>
          </div>
        </div>
      </div>
    </div>
    <l-map ref="myMap" :zoom="zoom" :center="center" :options="options" style="height: 100vh;">
      <!-- 載入圖資 -->
      <l-tile-layer :url="url" :attribution="attribution" />
      <!-- 點擊左側自動帶入popup -->
      <l-marker ref="marker" :lat-lng="chooseLocal.local" v-if="chooseLocal.name != null">
        <l-icon :icon-url="icon.type.gold" :shadow-url="icon.shadowUrl" :icon-size="icon.iconSize"
          :icon-anchor="icon.iconAnchor" :popup-anchor="icon.popupAnchor" :shadow-size="icon.shadowSize" />
        <l-popup ref="popup">
          <p class="poptitle">{{ chooseLocal.name }}</p>
          <img :src="chooseLocal.imgUrl" :alt="chooseLocal.imgName" />
          <b-row cols="2" class="pt-2">
            <b-col><b-link :to="'ShopInfo?id=' + chooseLocal.id" class="btn btn-outline-secondary">詳細資料</b-link>
            </b-col>
            <b-col><a :href="chooseLocal.googleMapUrl" target="_blank" class="btn btn-info">
                導航連結
              </a></b-col>
          </b-row>
        </l-popup>
      </l-marker>
      <!-- 座標 -->
      <l-marker :lat-lng="item.local" v-for="item in storeList" :key="item.id">
        <l-icon :icon-url="icon.type.black" :shadow-url="icon.shadowUrl" :icon-size="icon.iconSize"
          :icon-anchor="icon.iconAnchor" :popup-anchor="icon.popupAnchor" :shadow-size="icon.shadowSize" />
        <l-popup>
          <p class="poptitle">{{ item.name }}</p>
          <img :src="item.imgUrl" :alt="item.imgName" />
          <b-row cols="2" class="pt-2">
            <b-col><b-link :to="'ShopInfo?id=' + item.id" class="btn btn-outline-secondary">詳細資料</b-link>
            </b-col>
            <b-col><a :href="item.googleMapUrl" target="_blank" class="btn btn-info">
                導航連結
              </a></b-col>
          </b-row>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      chooseLocal: {
        local: [47.31322, -1.319482],
        name: null,
        id: null,
        imgUrl: null,
        imgName: null,
        googleMapUrl: null,
      },
      sideBarShow: true,
      items: [
        {
          text: "首頁",
          href: "#/",
        },
        {
          text: "Map",
          active: false,
        },
      ],
      loading: true,
      searchItemsShow: true,
      search: {
        keywords: null,
        time: [
          { title: "星期一", status: false },
          { title: "星期二", status: false },
          { title: "星期三", status: false },
          { title: "星期四", status: false },
          { title: "星期五", status: false },
          { title: "星期六", status: false },
          { title: "星期日", status: false },
          { title: "預約制", status: false },
        ],
        pay: [
          { status: false, title: "現金" },
          { status: false, title: "轉帳" },
          { status: false, title: "貨到付款" },
          { status: false, title: "信用卡" },
          { status: false, title: "行動支付" },
        ],
        variety: [
          { status: false, title: "紅茶" },
          { status: false, title: "咖啡" },
          { status: false, title: "蘭花" },
          { status: false, title: "香菇" },
          { status: false, title: "蔬果與其他" },
        ],
        BlackTeaType: [
          { status: false, title: "阿薩姆紅茶" },
          { status: false, title: "紅韻紅茶" },
          { status: false, title: "紅玉紅茶" },
          { status: false, title: "原生山茶" },
        ],
        serviceType: [
          { status: false, title: "零售" },
          { status: false, title: "批發" },
          { status: false, title: "輕鬆坐下來" },
          { status: false, title: "體驗服務" },
          { status: false, title: "住宿" },
        ],
        permission: [
          { status: true, title: "有" },
          { status: true, title: "無" },
        ],
      },
      storeList: [],
      zoom: 13,
      center: [23.879858450769774, 120.92476660037254],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: `© 魚池鄉公所版權所有`,
      options: {
        zoomControl: false,
      },
      icon: {
        type: {
          black:
            "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png",
          gold:
            "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png",
        },
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      },
      apiStore: (data) => this.userRequest.post("Default/Store", data),
    };
  },
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    setPosition(item) {
      console.log(item);
      this.$refs.myMap.mapObject.flyTo(item.local, 18);
      this.chooseLocal = item;
      this.$nextTick(() => {
        this.$refs.marker.mapObject.openPopup();
      });
    },
    init() {
      this.loading = true;
      let json = {
        TYPE: "FRONT_LIST_MAP",
        STORE_SELECT: {
          keywords: this.search.keywords,
          time: {
            Mon: this.search.time[0].status,
            Tue: this.search.time[1].status,
            Wed: this.search.time[2].status,
            Thu: this.search.time[3].status,
            Fri: this.search.time[4].status,
            Sat: this.search.time[5].status,
            Sun: this.search.time[6].status,
            Reserved: this.search.time[7].status,
          },
          pay: {
            Cash: this.search.pay[0].status,
            Transfer: this.search.pay[1].status,
            Delivery: this.search.pay[2].status,
            CreditCard: this.search.pay[3].status,
            MobilePay: this.search.pay[4].status,
          },
          variety: {
            BlackTea: this.search.variety[0].status,
            Coffee: this.search.variety[1].status,
            Orchid: this.search.variety[2].status,
            Mushroom: this.search.variety[3].status,
            Fruits: this.search.variety[4].status,
          },

          serviceType: {
            Sale: this.search.serviceType[0].status,
            WholeSale: this.search.serviceType[1].status,
            Seat: this.search.serviceType[2].status,
            Experience: this.search.serviceType[3].status,
            Stay: this.search.serviceType[4].status,
          },
          permission: {
            yes: this.search.permission[0].status,
            no: this.search.permission[1].status,
          },
        },
      };
      if (this.search.variety[0].status) {
        json.STORE_SELECT.blackTea = {
          BlackTea1: this.search.BlackTeaType[0].status,
          BlackTea2: this.search.BlackTeaType[1].status,
          BlackTea3: this.search.BlackTeaType[2].status,
          BlackTea4: this.search.BlackTeaType[3].status,
        }
      } else {
        json.STORE_SELECT.blackTea = {
          BlackTea1: false,
          BlackTea2: false,
          BlackTea3: false,
          BlackTea4: false,
        }
      }
      this.FunctionToken(this.APIGetStoreList, json);
    },
    //api
    APIGetStoreList(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.storeList = [];
            jsonData.forEach((x) => {
              if (x.SO_YAXIS != null && x.SO_XAXIS != null) {
                let obj = {
                  id: x.SO_ID,
                  name: x.SO_NAME,
                  local: [x.SO_YAXIS, x.SO_XAXIS],
                  imgUrl: x.MainImgUrl,
                  imgName: x.ME_NAME.split(".")[0],
                  address: "南投縣魚池鄉" + x.SO_ADDRESS,
                  googleMapUrl: x.SO_GOOGLEMAP,
                };
                this.storeList.push(obj);
              }
            });
            console.log(this.storeList);
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
