<template>
  <div>
    <div class="header">
      <a
        href="#Accesskey_C"
        id="gotocenter"
        title="移至主要內容"
        class="Go-content"
        >跳到主要內容</a
      >
      <!-- <div class="accesskey">
        <a href="#Accesskey_U" id="Accesskey_U" accesskey="U" title="上方選單連結區，此區塊列有本網站的主要連結">:::</a>
      </div> -->
      <b-container fluid class="TopNavGroup">
        <b-row align-h="between" align-v="center">
          <b-col cols="auto">
            <h1 class="mainLogo">
              <b-link to="/" title="返回 魚池農易GO 首頁">魚池農易GO</b-link>
            </h1>
          </b-col>
          <b-col cols="auto">
            <b-navbar class="main-nav-items">
              <b-navbar-nav class="ml-auto">
                <b-nav-item to="/">首頁</b-nav-item>
                <b-nav-item-dropdown text="紅茶業者">
                  <b-dropdown-item to="/BlackTea?type=1"
                    >紅茶業者</b-dropdown-item
                  >
                  <b-dropdown-item to="/BlackTea?type=2"
                    >日月潭紅茶發展史</b-dropdown-item
                  >
                  <b-dropdown-item to="/BlackTea?type=3"
                    >四大品種介紹</b-dropdown-item
                  >
                  <b-dropdown-item to="/BlackTea?type=4"
                    >日月潭紅茶風味輪</b-dropdown-item
                  >
                </b-nav-item-dropdown>
                <b-nav-item to="Mushroom">香菇業者</b-nav-item>
                <b-nav-item to="Orchid">蘭花業者</b-nav-item>
                <b-nav-item to="Coffee">咖啡業者</b-nav-item>
                <b-nav-item to="Vegetable">蔬果與其他業者</b-nav-item>
                <b-nav-item to="ShopMap">業者地圖</b-nav-item>
              </b-navbar-nav>
            </b-navbar>
            <!-- <nav class="main-nav-items">
              <ul>
                <li>
                <b-link to="/">首頁</b-link>
              </li>
              <li>
                <b-link to="BlackTea">紅茶館</b-link>
              </li>
              <li>
                <b-link to="infoPowerSaving">香菇館</b-link>
              </li>
              <li>
                <b-link to="infoGovernment">蘭花館</b-link>
              </li>
              <li>
                <b-link to="infoVideo">咖啡館</b-link>
              </li>
              </ul>
            </nav> -->
          </b-col>
          <b-col cols="auto">
            <b-link
              class="MainNavBtn"
              @click="navBarActive = !navBarActive"
              value="顯示導覽列"
              title="顯示導覽列"
              alt="顯示導覽列"
              ><i
                class="fa"
                :class="{ 'fa-bars': !navBarActive, 'fa-times': navBarActive }"
                aria-hidden="true"
              ></i
              ><span class="sr-only">導覽列</span></b-link
            >
            <!-- <b-link class="MainNavBtn"><i class="fa fa-times"></i></b-link> -->
          </b-col>
        </b-row>

        <nav class="TopNav">
          <div class="nav-items" :class="{ navSlider: navBarActive }">
            <b-container>
              <ul class="nav-itemsTOP">
                <li>
                  <b-link
                    to="/"
                    @click="navBarActive = !navBarActive"
                    title="返回 魚池農易GO 首頁"
                    >首頁</b-link
                  >
                </li>
                <li>
                  <b-link
                    to="BlackTea"
                    @click="navBarActive = !navBarActive"
                    title="前往 紅茶業者"
                    >紅茶業者</b-link
                  >
                </li>
                <li>
                  <b-link
                    to="Mushroom"
                    @click="navBarActive = !navBarActive"
                    title="前往 香菇業者"
                    >香菇業者</b-link
                  >
                </li>
                <li>
                  <b-link
                    to="Orchid"
                    @click="navBarActive = !navBarActive"
                    title="前往 蘭花館"
                    >蘭花業者</b-link
                  >
                </li>
                <li>
                  <b-link
                    to="Coffee"
                    @click="navBarActive = !navBarActive"
                    title="前往 咖啡業者"
                    >咖啡業者</b-link
                  >
                </li>
                <li>
                  <b-link
                    to="Vegetable"
                    @click="navBarActive = !navBarActive"
                    title="前往 蔬果與其他業者"
                    >蔬果與其他業者</b-link
                  >
                </li>
                <li>
                  <b-link
                    to="ShopMap"
                    @click="navBarActive = !navBarActive"
                    title="前往 業者地圖"
                    >業者地圖</b-link
                  >
                </li>
              </ul>
            </b-container>
          </div>
        </nav>
      </b-container>
    </div>
    <router-view />
    <div class="footer">
      <!-- <b-img
        :src="require('@/assets/imgs/footer.png')"
        fluid
        alt=""
      ></b-img> -->
      <div class="footerinfo">
        <b-container fluid class="pr-5">
          <b-row align-h="between">
            <b-col cols="auto" align-self="center">
              <b-img
                :src="require('@/assets/imgs/logo_footer.png')"
                fluid
                alt="南投縣魚池鄉公所"
              ></b-img>
            </b-col>
            <b-col cols="auto" align-self="center">
              <div>
                Copyright © Yuchih Township office. All rights reserved.
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        navBarActive: false,
      };
    },
    watch: {},
    mounted() {},
    methods: {},
  };
</script>
<style lang="scss">
  .header {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
    -o-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(5px 5px 5spx rgba(0, 0, 0, 0.2));

    a {
      &.Go-content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: #ffbf47;
        color: #000;
        height: 0;
        overflow: hidden;
        -webkit-transition: top 1s ease-out;
        transition: top 1s ease-out;
        z-index: 9999;

        &:focus {
          height: auto;
          padding: 1rem;
          overflow: none;
        }
      }
    }

    .TopNavGroup {
      position: relative;

      .mainLogo {
        z-index: 9998;
        margin: 0;

        a {
          background-image: url(../assets/imgs/logo_header.png);
          background-repeat: no-repeat;
          background-position: center;
          display: block;
          width: 280px;
          height: 45px;
          margin: 2px 0;
          background-size: contain;
          font: 0/0 a;
          color: transparent;
          z-index: 9998;
          position: relative;
        }
      }

      .siteMapBtn {
        width: 70px;
        height: 70px;
        display: block;
        background-color: transparent;
        background-image: url(../assets/imgs/sitemap.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        font-size: 0rem;
        text-indent: -9999px;
        overflow: hidden;
        position: absolute;
        right: 5rem;
        top: 1rem;
      }
    }

    .TopNav {
      display: flex;
      text-align: center;
      font-size: 3rem;
    }

    .nav-items {
      font-size: 2rem;
      list-style: none;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      font-weight: 500;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(../assets/imgs/menu_bg.png);
      background-position: bottom -20rem center;
      background-color: #f3f5f3;
      z-index: 1;
      position: absolute;
      display: none;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
      -o-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
      -webkit-filter: drop-shadow(5px 5px 5spx rgba(0, 0, 0, 0.2));

      li {
        display: inline-block;
      }

      .nav-itemsTOP {
        padding: 1rem 0;

        li {
          a {
            color: #123e54;
            margin: 0 1.5rem;
            padding: 0.5rem 0;
            overflow: hidden;
            position: relative;

            &:before {
              left: 0;
              bottom: 0;
              width: 100%;
              height: 4px;
              background: #123e54;
              content: "";
              position: absolute;
              transform: scaleX(0);
              transition: transform 0.5s ease;
            }

            &:hover {
              &:before {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }

    h2 {
      margin: auto;
      z-index: 3;
      color: #123e54;
      transform: translate(-40px);
    }

    .MainNavBtn {
      padding: 0;
      margin: 0;
      z-index: 9999;
      font-size: 2.5rem;
      position: absolute;
      right: 1rem;
      top: -2rem;

      // z-index: 9999;
      // font-size: 2.5rem;

      i {
        color: #123e54;
      }
    }

    // @keyframes slide {
    //   0% {
    //     right: 140%;
    //   }

    //   100% {
    //     right: 0;
    //   }
    // }

    .navSlider {
      // animation-name: slide;
      // animation-duration: 0.4s;
      // animation-iteration-count: 1;
      display: block;
    }
  }

  .footer {
    .footerinfo {
      padding: 0.5rem 0;
      background-color: #004e48;
      color: #fff;
    }
  }

  @media (min-width: 1920px) {
    .navSlider {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: 1025px) {
    .header {
      position: absolute;
      top: 0;
      z-index: 9000;
      background-color: #fff;
      .navSlider {
        height: 40vh;
        padding-top: 6rem;
      }
    }
    .TopNavGroup {
      .main-nav-items {
        ul {
          & > li {
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .header {
      .TopNavGroup {
        .main-nav-items {
          display: none;
        }
        .nav-items {
          background-position: bottom center;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .header {
      position: fixed;
      z-index: 9998;
      top: 0;
      background-color: #fff;

      .TopNavGroup {
        .mainLogo {
          margin: 0;
          a {
            width: 15rem;
          }
        }

        .siteMapBtn {
          width: 35px;
          height: 35px;
          right: 3rem;
          top: 1.4rem;
        }
      }

      .navSlider {
        padding-top: 5rem;
      }

      .nav-items {
        height: 100vh;
        ul {
          margin: 0;
          li {
            display: block;
            margin-bottom: 0.5rem;
          }
        }

        .sub-link {
          li {
            display: inline-block;
          }
        }
      }

      .MainNavBtn {
        font-size: 2rem;
        top: -1.6rem;
      }
    }
  }

  @media (max-width: 641px) {
    .header {
      .TopNav {
        font-size: 2.5rem;

        i {
          margin-top: 20px;
        }
      }
    }
  }
</style>
