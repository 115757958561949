<template>
  <div>
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav>
        <b-nav-item>魚池鄉農產業資訊系統</b-nav-item>
        <b-nav-item href="#/admin/count">瀏覽人次</b-nav-item>
        <b-nav-item href="#/admin/store">業者登錄</b-nav-item>
        <b-nav-item @click="functionLogOut">登出</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <router-view />
    <b-modal v-model="logOut" title="提示" ok-only>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click="_go('/Login')"
        >
          確定
        </button>
      </template>
      <p class="my-4">{{ message }}</p>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "bar",
    data() {
      return {
        message: null,
        logOut: false,
        apiAccount: (data) => this.userRequest.post("Default/Account", data),
      };
    },
    mounted() {
      this.functionCheckLogin();
    },
    watch: {
      "$route.query"() {
        this.functionCheckLogin();
      },
    },
    methods: {
      functionCheckLogin() {
        let json = {
          TYPE: "CHECKSESSION",
          login_SELECT: {},
        };
        this.FunctionToken(this.APIFunctionCheckLogin, json);
      },
      functionLogOut() {
        let json = {
          TYPE: "LOGOUT",
          ACCOUNT_SELECT: {},
        };
        this.FunctionToken(this.ApiFunctionLogout, json);
      },
      // -------API----------------
      APIFunctionCheckLogin(data) {
        this.apiAccount(data)
          .then((res) => {
            const json = JSON.parse(res.data);
            if (!json.Status) {
              this.message = json.Data;
              this.logOut = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      ApiFunctionLogout(data) {
        this.apiAccount(data)
          .then((res) => {
            const json = JSON.parse(res.data);
            if (json.Status) {
              this.message = json.Data;
              this.logOut = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>
