import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/Home';
import ShopMap from '../views/ShopMap';
import BlackTea from '../views/BlackTea';
import Mushroom from '../views/Mushroom';
import Orchid from '../views/Orchid';
import Coffee from '../views/Coffee';
import Vegetable from '../views/Vegetable';
import ShopInfo from '../views/ShopInfo';
import login from '../views/login';
import userBar from '../components/userBar.vue';
import adminBar from '../components/adminBar.vue';
import admin_store from '../views/admin/store.vue';
import admin_count from '../views/admin/count.vue';




Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: userBar,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/ShopMap",
        name: "ShopMap",
        component: ShopMap,
      },
      {
        path: "/BlackTea",
        name: "BlackTea",
        component: BlackTea,
      },
      {
        path: "/Mushroom",
        name: "Mushroom",
        component: Mushroom,
      },
      {
        path: "/Orchid",
        name: "Orchid",
        component: Orchid,
      },
      {
        path: "/Coffee",
        name: "Coffee",
        component: Coffee,
      },
      {
        path: "/Vegetable",
        name: "Vegetable",
        component: Vegetable,
      },
      {
        path: "/ShopInfo",
        name: "ShopInfo",
        component: ShopInfo,
      },
      {
        path: "/login",
        name: "login",
        component: login,
      },
    ]
  },
  {
    path: "/backend",
    component: adminBar,
    children: [
      {
        path: '/admin/store',
        name: 'admin_store',
        component: admin_store,
      },
      {
        path: '/admin/count',
        name: 'admin_count',
        component: admin_count,
      },
    ]
  },

];


const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;