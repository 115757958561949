<template>
  <div class="Page inner">
    <div class="innerPageHead">
      <div class="innerPageBanner bleakTea">
        <b-container>
          <b-row align-h="center" align-v="center" cols="2">
            <b-col class="text-center">
              <div
                class="innerPageBannerPic pt-md-5 px-md-5 pb-md-3 pt-5 px-4 pb-2"
              >
                <b-img
                  :src="require('@/assets/imgs/icon_interpage_blacktea.png')"
                  fluid
                  alt="紅茶業者"
                ></b-img>
              </div>
              <h2 class="innerPageBannerTitle">紅茶業者</h2>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container>
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-container>
    </div>
    <div class="innerPageSubNav">
      <b-container class="innerPageSubNavGroup">
        <b-row align-h="center">
          <b-col cols="6" lg="auto" class="innerPageSubNavItem">
            <b-link :class="{ active: type == 1 }" href="#/BlackTea?type=1">
              紅茶業者
            </b-link>
          </b-col>
          <b-col cols="6" lg="auto" class="innerPageSubNavItem">
            <b-link :class="{ active: type == 2 }" href="#/BlackTea?type=2">
              日月潭紅茶發展史
            </b-link>
          </b-col>
          <b-col cols="6" lg="auto" class="innerPageSubNavItem">
            <b-link :class="{ active: type == 3 }" href="#/BlackTea?type=3">
              四大品種介紹
            </b-link>
          </b-col>
          <b-col cols="6" lg="auto" class="innerPageSubNavItem">
            <b-link :class="{ active: type == 4 }" href="#/BlackTea?type=4">
              日月潭紅茶風味輪
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="shopListGroup" v-if="type == 1">
      <b-container>
        <b-row cols="2" cols-sm="2" cols-md="3" cols-lg="4">
          <b-col
            class="shopListItem"
            v-for="item in storeList"
            :key="item.SO_ID"
          >
            <b-link :to="'Shopinfo?id=' + item.SO_ID" class="drop-shadow">
              <div class="shopListPic">
                <b-img :src="item.MainImgUrl" fluid :alt="item.ME_NAME"></b-img>
              </div>
              <div class="shopListTitle">
                <h3>{{ item.SO_NAME }}</h3>
              </div>
            </b-link>
          </b-col>
        </b-row>

        <div class="page">
          <b-pagination
            class="pagination light-theme simple-pagination"
            v-model="pagination.page"
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-container>
    </div>

    <div v-if="type == 2">
      <b-container class="blackTeaHistory">
        <b-carousel id="carousel-1" :interval="4000" controls>
          <b-carousel-slide
            v-for="(item, index) in type2"
            :key="index"
            style="height: 100vh; background-color: transparent"
          >
            <div class="element">
              <div class="HistoryGroupAll">
                <div class="HistoryGroupPic">
                  <b-img :src="item.imageUrl" fluid alt="喝得到的風土"></b-img>
                </div>
                <div class="HistoryGroupLine"></div>
                <div class="HistoryGroup"></div>
                <p style="color: black; text-align: justify">{{ item.text }}</p>
              </div>
            </div>
          </b-carousel-slide>
        </b-carousel>
      </b-container>
    </div>

    <div v-if="type == 3">
      <b-container class="blackTeaVariety">
        <b-row align-h="center" cols="1" cols-lg="2" cols-xl="2">
          <b-col>
            <b-img
              :src="require('@/assets/imgs/HistoryGroupPic_1.png')"
              fluid
              alt="喝得到的風土"
            ></b-img>
            <p>
              如果想知道風土滋味，一定要試試看日月潭紅茶。台茶18號(紅玉紅茶)、台茶21號(紅韻紅茶)、台茶8號(阿薩姆紅茶)、日月潭山茶是日月潭的四大茶樹品種，其中又以台茶18號最具辨識性，喝來有明顯的薄荷涼感，喝過幾乎不會忘記。
            </p>
          </b-col>
        </b-row>
        <b-row align-h="center" cols="1" cols-lg="2" cols-xl="2">
          <b-col>
            <h3>阿薩姆紅茶</h3>
            <div class="pb-2"><span class="badge malt">麥芽香</span></div>
            <p>
              1925年「魚池紅茶試驗支所」(現茶改場魚池分場前身)，自印度引進阿薩姆茶種，為日月潭種植紅茶的濫觴，不僅是日本天皇的御用珍品，也讓台灣紅茶成為民國40-60年農產品的銷售主力。茶湯深紅，滋味甘醇，除了純飲，也很適合加入鮮奶飲用。
            </p>
          </b-col>
        </b-row>
        <b-row align-h="center" cols="1" cols-lg="2" cols-xl="2">
          <b-col>
            <h3>紅韻紅茶<span class="text-secondary">(台茶21號)</span></h3>
            <div class="pb-2">
              <span class="badge flower mr-2">柚花蜜</span
              ><span class="badge fruity">熟果香</span>
            </div>
            <p>
              以祈門茶種與印度大葉種Kyang選育而來，茶湯水色金紅明亮，滋味甘甜鮮爽，帶有濃郁花果香，成茶香氣帶有類似柑桔植物開花時所散發之花香。
            </p>
          </b-col>
        </b-row>
        <b-row align-h="center" cols="1" cols-lg="2" cols-xl="2">
          <b-col>
            <h3>
              紅玉紅茶<span class="text-secondary">(台茶18號、台灣香)</span>
            </h3>
            <div class="pb-2">
              <span class="badge mint mr-2">薄荷</span
              ><span class="badge biscuity">肉桂</span>
            </div>
            <p>
              以台灣山茶與緬甸大葉種Burma，歷經五十多年育種試驗選育而來，散發淡淡的薄荷肉桂香，極具辨識性，茶湯入口後的微澀收斂感，源自於原生種台灣山茶經發酵後的豐富內涵表現，被譽為「台灣香」，其獨特風味可代表台灣出戰與世界紅茶爭鋒。
            </p>
          </b-col>
        </b-row>
        <b-row align-h="center" cols="1" cols-lg="2" cols-xl="2">
          <b-col>
            <h3>原生山茶</h3>
            <div class="pb-2"><span class="badge woody">木質香</span></div>
            <p>
              不同於從國外引進的品種，此為生長於日月潭(水沙漣)的原生種茶樹，歷經百年的扎根，汰弱留強後的禮物，屬於原生的古老茶種，最早文字記載於清朝的《諸羅縣志》，茶味清新，具淡淡木質調性。
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="type == 4">
      <b-container class="mb-5">
        <b-row class="justify-content-center">
          <b-col cols="auto" class="chartgroup justify-content-center">
            <v-chart
              class="chartB"
              :option="optionB"
              @click="getInformationB"
            />
            <b-card :title="snburInfoB.title" v-if="snburInfoB.title">
              <b-card-text>
                {{ FlovorBinfo[snburInfoB.dataIndex] }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row cols="1" cols-lg="2" cols-xl="2" align-h="center">
          <b-col cols="auto" class="chartgroup">
            <v-chart
              class="chartAC"
              :option="optionA"
              @click="getInformationA"
            />
            <!-- <b-card :title="snburInfoA.title" v-if="snburInfoA.title">
              <b-card-text>
                {{ FlovorBinfo[snburInfoA.dataIndex] }}
              </b-card-text>
            </b-card> -->
          </b-col>
          <b-col cols="auto" class="chartgroup">
            <v-chart
              class="chartAC"
              :option="optionC"
              @click="getInformationC"
            />
            <b-card :title="snburInfoC.title" v-if="snburInfoC.title">
              <b-card-text>
                {{ FlovorCinfo[snburInfoC.dataIndex] }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <ul id="myMenu">
      <li>
        <a href="/" tooltip="首頁" flow="left"
          ><span class="icon-home3" title="返回 魚池農易GO 首頁"></span
          ><span class="sr-only">首頁</span>
        </a>
      </li>

      <li>
        <a
          href="javascript:"
          v-scroll-to="'#top'"
          class="BackToTop"
          title="回到最頂[連結]"
          ><span class="icon-top"></span><span class="sr-only">top</span></a
        >
      </li>
    </ul>
  </div>
</template>
<script>
  import { use } from "echarts/core";
  import * as echarts from "echarts/core";
  import { CanvasRenderer } from "echarts/renderers";
  import { SunburstChart } from "echarts/charts";
  import HScroll from "@koas/v-hscroll";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
  } from "echarts/components";
  import VChart, { THEME_KEY } from "vue-echarts";

  use([
    CanvasRenderer,
    SunburstChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
  ]);

  export default {
    components: {
      VChart,
    },
    provide: {
      [THEME_KEY]: "",
    },
    data() {
      let FlovorA = [
        {
          name: "黃\nYellow",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
              {
                offset: 1,
                color: "#DD7F1A",
              },
              {
                offset: 0,
                color: "#E68212",
              },
            ]),
          },
        },
        {
          name: "橙黃\nOrange",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#e3ab77",
              },
              {
                offset: 0,
                color: "#e45c21",
              },
            ]),
          },
        },
        {
          name: "橙\nDeep Orange",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#efa87e",
              },
              {
                offset: 0,
                color: "#df5d1d",
              },
            ]),
          },
        },
        {
          name: "橙紅\nReddish Orange",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(1, 1, 0, 0, [
              {
                offset: 1,
                color: "#f19d75",
              },
              {
                offset: 0,
                color: "#e14a1f",
              },
            ]),
          },
        },
        {
          name: "金紅\nBronze Red",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#e8996d",
              },
              {
                offset: 0,
                color: "#d34329",
              },
            ]),
          },
        },
        {
          name: "紅\nRed",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#e7886e",
              },
              {
                offset: 0,
                color: "#d1222f",
              },
            ]),
          },
        },
        {
          name: "豔紅\nScarlet Red",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#e88a81",
              },
              {
                offset: 0,
                color: "#d81c3f",
              },
            ]),
          },
        },
        {
          name: "暗紅\nDark Red",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#e4847a",
              },
              {
                offset: 0,
                color: "#ac2f2a",
              },
            ]),
          },
        },
        {
          name: "褐\nBrown",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#d87772",
              },
              {
                offset: 0,
                color: "#b1222a",
              },
            ]),
          },
        },
        {
          name: "暗褐\nDeep Brown",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#d67774",
              },
              {
                offset: 0,
                color: "#922832",
              },
            ]),
          },
        },
      ];
      let FlovorB = [
        {
          name: "花香\nFlower",
          itemStyle: {
            color: "#F0919E",
          },
          children: [
            {
              name: "甜花香\nSweety Flower",
              value: 1,
              info: "dfdsff",
              itemStyle: {
                color: "#F0919E",
              },
            },
            {
              name: "柑橘花香\nCitrus Floral",
              value: 1,
              itemStyle: {
                color: "#ED7468",
              },
            },
          ],
        },
        {
          name: "果香\nFruity",
          itemStyle: {
            color: "#F08764",
          },
          children: [
            {
              name: "柑橘花香\nCitrus Floral",
              value: 1,
              itemStyle: {
                color: "#ED7468",
              },
            },
            {
              name: "果香\nFruity",
              value: 1,
              itemStyle: {
                color: "#F08764",
              },
            },
          ],
        },
        {
          name: "草本\nHerb",
          itemStyle: {
            color: "#A1CFB9",
          },
          children: [
            {
              name: "肉桂香\nBiscuity",
              value: 2,
              itemStyle: {
                color: "#EABA96",
              },
            },
            {
              name: "薄荷香\nMint",
              value: 2,
              itemStyle: {
                color: "#A1CFB9",
              },
            },
            {
              name: "麥芽香\nMalt",
              value: 2,
              itemStyle: {
                color: "#DAD980",
              },
            },
          ],
        },
        {
          name: "木質\nWoody",
          itemStyle: {
            color: "#C0A353",
          },
          children: [
            {
              name: "木質香\nWoody",
              value: 3,
              itemStyle: {
                color: "#C0A353",
              },
            },
          ],
        },
        {
          name: "甜味\nSweet",
          itemStyle: {
            color: "#C47217",
          },
          children: [
            {
              name: "焦糖味\nCaramel",
              value: 3,
              itemStyle: {
                color: "#C47217",
              },
            },
          ],
        },
        {
          name: "雜味\nUndsirable Odor",
          itemStyle: {
            color: "#846b70",
          },
          children: [
            {
              name: "菁味\nGreen",
              value: 1,
              itemStyle: {
                color: "#99a725",
              },
            },
            {
              name: "刺鼻味\nRasping",
              value: 1,
              itemStyle: {
                color: "#afc92a",
              },
            },
            {
              name: "茶袋味\nBaggy",
              value: 1,
              itemStyle: {
                color: "#D9C5C9",
              },
            },
            {
              name: "雜味\nTaints",
              value: 1,
              itemStyle: {
                color: "#937472",
              },
            },
            {
              name: "培火味\nBakey",
              value: 1,
              itemStyle: {
                color: "#D09500",
              },
            },
            {
              name: "焦味\nBurnt",
              value: 1,
              itemStyle: {
                color: "#813B00",
              },
            },
            {
              name: "泥土味\nEarthy",
              value: 1,
              itemStyle: {
                color: "#7E5900",
              },
            },
            {
              name: "金屬味\nMetallic",
              value: 1,
              itemStyle: {
                color: "#C1AAAF",
              },
            },
            {
              name: "煙味\nSmokey",
              value: 1,
              itemStyle: {
                color: "#F4E7D9",
              },
            },
            {
              name: "生味\nRaw",
              value: 1,
              itemStyle: {
                color: "#B4A600",
              },
            },
            {
              name: "雨水菁\nWeathery",
              value: 1,
              itemStyle: {
                color: "#9B9500",
              },
            },
            {
              name: "青草味\nWeedy",
              value: 1,
              itemStyle: {
                color: "#C6D800",
              },
            },
            {
              name: "發霉\nMusty",
              value: 1,
              itemStyle: {
                color: "#EDE4B6",
              },
            },
            {
              name: "高火\nHigh-Fired",
              value: 1,
              itemStyle: {
                color: "#D37500",
              },
            },
            {
              name: "陳味\nGone off",
              value: 1,
              itemStyle: {
                color: "#BD8F6D",
              },
            },
            {
              name: "酸\nSour",
              value: 1,
              itemStyle: {
                color: "#F4A14B",
              },
            },
          ],
        },
      ];
      let FlovorC = [
        {
          name: "收斂性\nAstringency",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#F3F3AE",
              },
              {
                offset: 0,
                color: "#E0C700",
              },
            ]),
          },
        },
        {
          name: "濃郁\nBody",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#F6CD44",
              },
              {
                offset: 0,
                color: "#DE9600",
              },
            ]),
          },
        },
        {
          name: "活性\nBrisk",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 1,
                color: "#E5C100",
              },
              {
                offset: 0,
                color: "#E57147",
              },
            ]),
          },
        },
        {
          name: "豐富\nFull",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 1,
                color: "#DC2F15",
              },
              {
                offset: 0,
                color: "#DD640B",
              },
            ]),
          },
        },
        {
          name: "濃烈\nStrength",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 1,
                color: "#E5607A",
              },
              {
                offset: 0,
                color: "#CD3149",
              },
            ]),
          },
        },
        {
          name: "厚\nThick",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#8D1D1A",
              },
              {
                offset: 0,
                color: "#EB5F44",
              },
            ]),
          },
        },
        {
          name: "苦味\nBitterness",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#55643A",
              },
              {
                offset: 0,
                color: "#A39E2F",
              },
            ]),
          },
        },
        {
          name: "平淡\nFlat",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#A0A06E",
              },
              {
                offset: 0,
                color: "#E8DCBE",
              },
            ]),
          },
        },
        {
          name: "欠圓滑\nDry",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#769F88",
              },
              {
                offset: 0,
                color: "#CCE6CB",
              },
            ]),
          },
        },
        {
          name: "強烈\nHard",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 1,
                color: "#28733C",
              },
              {
                offset: 0,
                color: "#325134",
              },
            ]),
          },
        },
        {
          name: "苦澀味\nHarsh",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#003F23",
              },
              {
                offset: 0,
                color: "#9D6328",
              },
            ]),
          },
        },
        {
          name: "濃重\nHeavy",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 1,
                color: "#11718C",
              },
              {
                offset: 0,
                color: "#724B4F",
              },
            ]),
          },
        },
        {
          name: "味淡\nLight",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 1,
                color: "#D9E4E5",
              },
              {
                offset: 0,
                color: "#8DB6B8",
              },
            ]),
          },
        },
        {
          name: "清淡\nPlain",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 1,
                color: "#DEE5F3",
              },
              {
                offset: 0,
                color: "#98B1DA",
              },
            ]),
          },
        },
        {
          name: "淡薄\nThin",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#BBBDC7",
              },
              {
                offset: 0,
                color: "#8D87A7",
              },
            ]),
          },
        },
        {
          name: "悶味\nStewed",
          value: 1,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#CBC252",
              },
              {
                offset: 0,
                color: "#0087A9",
              },
            ]),
          },
        },
      ];
      return {
        type2: [
          {
            imageUrl: require("@/assets/imgs/HistoryGroupPic_2.png"),
            text: "食材講究風土，風土指的種植的土壤、氣候、向陽背陽等生長環境，1925年日本人找遍全台，選定南投魚鄉日月潭為阿薩姆紅茶的最佳產地，開始種下一棵茶樹，開啟了日月潭紅茶的黃金年代，不但曾是進貢日本天皇的珍品，全盛時期種植面積高達四千公頃，是台灣茶外銷的重要主力。",
          },
          {
            imageUrl: require("@/assets/imgs/HistoryGroupPic_3.png"),
            text: "相較於印度、斯里蘭卡，氣候乾熱高溫，日月潭獨樹一格，舊稱水沙蓮的它，地理上有許多的水、山丘，全區在群山的環繞與600-800公尺海拔氣候下，溫度適中、濕度穩定，不但是全台最適合種植大葉種紅茶的產區，多年來，農委會茶改場魚池分場還不斷地進行品種選育，培育出台茶18號與台茶21號等獨特品種。",
          },
          {
            imageUrl: require("@/assets/imgs/HistoryGroupPic_4.png"),
            text: "印度、斯里蘭卡乾熱高溫所孕育出的紅茶，個性強烈、滋味濃烈、收斂性強;相反地，日月潭低海拔高濕度的特質，種植出來的紅茶，滋味溫和，收斂感較淡，在如此的微型氣候下，還創造出日月潭紅茶的甜水特質，其甜感非高溫後的焦糖化，而是風土環境所培育出來的。",
          },
          {
            imageUrl: require("@/assets/imgs/HistoryGroupPic_5.png"),
            text: "有鑒於世界紅茶的品飲趨勢，逐步從濃烈滋味轉向高香品飲，具有溫柔甜水個性的日潭紅茶正好符合此一潮流，當進口紅茶大多經過切段篩分，在日月潭乃至於台灣許多地方我們所買到的茶葉幾乎都是全葉茶，熱水一沖，即可品嚐到完整的茶芽與第一葉、第二葉(一心二葉)的豐富滋味，相較於切篩分過的紅茶，風味更有層次。 ",
          },
          {
            imageUrl: require("@/assets/imgs/HistoryGroupPic_6.png"),
            text: "從日治時期日本人引進阿薩姆茶樹於魚池鄉種植，日月潭歷經了幾十年的紅茶風光，後來隨著國外低價紅茶進入台灣，以及國人的品飲喜好逐漸轉向綠茶、烏龍茶，紅茶產業沒落，不少農民為了生計砍掉茶樹，改種植更有經濟價值的檳榔樹，直到1999年921大地震，地方人士有意識的重建在地產業，才重新把紅茶給種回來。",
          },
        ],
        snburInfoA: {
          title: "",
          dataIndex: "",
        },
        snburInfoB: {
          title: "",
          dataIndex: "",
        },
        snburInfoC: {
          title: "",
          dataIndex: "",
        },
        FlovorAinfo: [
          "1 說明",
          "2 說明",
          "3 說明",
          "4 說明",
          "5 說明",
          "6 說明",
          "7 說明",
          "8 說明",
          "9 說明",
          "10 說明",
        ],
        FlovorBinfo: [
          "1 說明",
          "2 說明",
          "茉莉花、柑橘花及玉蘭花",
          "臺茶21號品種香",
          "5 說明",
          "臺茶21號品種香",
          "百香果、芒果、白桃(蜜香)",
          "8 說明",
          "臺茶18號品種香(新欉易有)",
          "臺茶18號品種香",
          "臺茶8號品種香",
          "12 說明",
          "松木，成熟葉造成",
          "14 說明",
          "焦糖味 Caramel",
          "16 說明",
          "紅茶因發酵或萎凋不足，而產生不成熟的菁味",
          "一種非常刺鼻的劣質湯味",
          "雜味、潮濕味、霉味、陳味",
          "茶葉貯存不當，吸收煙、焦、陳霉等雜味",
          "烘焙過程不當，而致茶湯呈現過強焙火味",
          "乾燥溫度過高，或時間久，帶有焦味",
          "茶葉貯放於潮濕地方，吸收濕土氣味或為某些產地茶的特殊劣味",
          "一種強烈的銅銹味",
          "乾燥機漏煙所產生的煙味",
          "帶苦而使人感到不爽口之茶湯，加工不到位",
          "雨水菁等引起不愉快的滋味",
          "因萎凋石足而帶有青草味",
          "陳茶有霉味",
          "經高溫充分乾燥所產的風味，但並非焦味",
          "陳味 Gone off",
          "發酵過度之茶易呈酸味",
        ],
        FlovorCinfo: [
          "1 說明",
          "湯色明亮入口有刺激性與收歛感，北印度的高級茶多有此特性",
          "濃稠",
          "湯味濃厚而有活力，此表製造良好的茶葉",
          "湯色鮮艷，味濃而有活力",
          "指茶湯滋味濃烈而言",
          "茶湯具有身骨",
          "茶湯苦澀感",
          "湯色暗，淡而無味，指低劣的陳茶",
          "乾燥溫度過高，茶湯有火味。些微的焦味或是些微過度炒焙味",
          "湯味強烈而有刺激性",
          "湯味帶有青臭而苦者",
          "指湯色濃重，但並無活性可言",
          "湯味淡薄",
          "湯色清淡，缺乏特色",
          "茶湯淡而無味，同時缺乏應有的特性",
          "茶葉乾燥時溫度不足，且堆置過厚，通氣不良，致滋味氣力不良",
        ],

        navigationActive: [],
        items: [
          {
            text: "首頁",
            href: "#/",
          },
          {
            text: "紅茶館",
            active: true,
          },
        ],
        type: null,
        storeListAll: [],
        storeList: [],
        pagination: {
          perPage: 8,
          page: 1,
          rows: 5,
        },
        apiStore: (data) => this.userRequest.post("Default/Store", data),
        optionA: {
          title: {
            text: "水色",
            subtext: "Tea Colors",
            textStyle: {
              fontSize: 24,
              align: "center",
            },
            subtextStyle: {
              align: "center",
            },
          },
          series: {
            type: "sunburst",
            data: FlovorA,
            radius: [0, "95%"],
            sort: undefined,
            emphasis: {
              focus: "ancestor",
            },
            levels: [
              {},
              {
                r0: "15%",
                r: "95%",
                itemStyle: {
                  borderWidth: 2,
                },
                label: {
                  color: "#ffffff",
                  fontSize: 14,
                },
              },
            ],
          },
        },
        optionB: {
          title: {
            text: "風味",
            subtext: "Flavor",
            textStyle: {
              fontSize: 24,
              align: "center",
            },
            subtextStyle: {
              align: "center",
            },
            // sublink: 'https://worldcoffeeresearch.org/work/sensory-lexicon/'
          },
          series: {
            type: "sunburst",
            data: FlovorB,
            radius: [0, "95%"],
            sort: undefined,
            emphasis: {
              focus: "ancestor",
            },
            levels: [
              {},
              {
                r0: "15%",
                r: "35%",
                itemStyle: {
                  borderWidth: 2,
                },
                label: {
                  rotate: "tangential",
                  fontSize: 11,
                },
              },
              {
                r0: "35%",
                r: "95%",
                label: {
                  fontSize: 11,
                },
              },
            ],
          },
        },
        optionC: {
          title: {
            text: "滋味",
            subtext: "Taste",
            textStyle: {
              fontSize: 24,
              align: "center",
            },
            subtextStyle: {
              align: "center",
            },
            // sublink: 'https://worldcoffeeresearch.org/work/sensory-lexicon/'
          },
          series: {
            type: "sunburst",
            data: FlovorC,
            radius: [0, "95%"],
            sort: undefined,
            emphasis: {
              focus: "ancestor",
            },
            levels: [
              {},
              {
                r0: "15%",
                r: "95%",
                itemStyle: {
                  borderWidth: 2,
                },
                label: {
                  rotate: "tangential",
                  fontSize: 11,
                  color: "#ffffff",
                },
              },
            ],
          },
        },
      };
    },
    mounted() {
      this.init();
      // this.scrollAnimation();
    },
    watch: {
      "$route.query"() {
        this.init();
      },
      "pagination.page"() {
        this.loading = true;
        this.setPage();
      },
    },
    methods: {
      init() {
        this.type = this.$route.query.type;
        if (this.type == undefined) this.type = 1;
        if (this.type == 1) {
          let json = {
            TYPE: "FRONT_LIST",
            STORE_SELECT: {
              variety: {
                BlackTea: true,
                Coffee: false,
                Orchid: false,
                Mushroom: false,
                Fruits: false,
              },
            },
          };
          this.FunctionToken(this.APIGetStoreList, json);
        }
      },
      getInformationA(params) {
        if (params.treePathInfo.length == 2) {
          console.log(params.treePathInfo[1].dataIndex);
          this.snburInfoA.title = params.treePathInfo[1].name;
          this.snburInfoA.dataIndex = params.treePathInfo[1].dataIndex;
        } else {
          this.snburInfoA.title = "";
          this.snburInfoA.dataIndex = "";
        }
      },
      getInformationB(params) {
        if (params.treePathInfo.length == 3) {
          console.log(params.treePathInfo[2].dataIndex);
          this.snburInfoB.title = params.treePathInfo[2].name;
          this.snburInfoB.dataIndex = params.treePathInfo[2].dataIndex;
        } else {
          this.snburInfoB.title = "";
          this.snburInfoB.dataIndex = "";
        }
      },
      getInformationC(params) {
        if (params.treePathInfo.length == 2) {
          console.log(params.treePathInfo[1].dataIndex);
          this.snburInfoC.title = params.treePathInfo[1].name;
          this.snburInfoC.dataIndex = params.treePathInfo[1].dataIndex;
        } else {
          this.snburInfoC.title = "";
          this.snburInfoC.dataIndex = "";
        }
      },
      setPage() {
        this.storeList = [];
        let count = this.pagination.perPage * this.pagination.page;
        let count1 = count - this.pagination.perPage;
        this.storeList = this.storeListAll.slice(count1, count);
        this.loading = false;
      },
      // api
      APIGetStoreList(data) {
        this.apiStore(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              console.log(jsonData);
              this.storeListAll = jsonData.outData;
              this.storeList = [];
              this.pagination.rows = jsonData.totalCount;
              this.setPage();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      scrollAnimation() {
        this.$nextTick(() => {
          setStickyContainersSize();
          bindEvents();
        });

        function bindEvents() {
          window.addEventListener("wheel", wheelHandler);
        }

        function setStickyContainersSize() {
          document
            .querySelectorAll(".sticky-container")
            .forEach(function (container) {
              const stikyContainerHeight =
                container.querySelector("main").scrollWidth;
              container.setAttribute(
                "style",
                "height: " + stikyContainerHeight + "px"
              );
            });
        }

        function isElementInViewport(el) {
          const rect = el.getBoundingClientRect();
          return (
            rect.top <= 0 && rect.bottom > document.documentElement.clientHeight
          );
        }

        function wheelHandler(evt) {
          const containerInViewPort = Array.from(
            document.querySelectorAll(".sticky-container")
          ).filter(function (container) {
            return isElementInViewport(container);
          })[0];

          if (!containerInViewPort) {
            return;
          }

          var isPlaceHolderBelowTop =
            containerInViewPort.offsetTop < document.documentElement.scrollTop;
          var isPlaceHolderBelowBottom =
            containerInViewPort.offsetTop + containerInViewPort.offsetHeight >
            document.documentElement.scrollTop;
          let g_canScrollHorizontally =
            isPlaceHolderBelowTop && isPlaceHolderBelowBottom;

          if (g_canScrollHorizontally) {
            containerInViewPort.querySelector("main").scrollLeft += evt.deltaY;
          }
        }
      },
    },
  };
</script>
<style>
  /**
 * 默认尺寸为 600px×400px，如果想让图表响应尺寸变化，可以像下面这样
 * 把尺寸设为百分比值（同时请记得为容器设置尺寸）。
 */

  .chartB,
  .chartAC {
    width: 320px;
    height: 320px;
  }

  @media (min-width: 1025px) {
    .chartB {
      width: 800px !important;
      height: 800px !important;
    }

    .chartAC {
      width: 500px;
      height: 500px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .chartB,
    .chartAC {
      width: 500px !important;
      height: 500px !important;
    }
  }

  .outer {
    width: 100%;
  }

  .inner-1 {
    overflow: hidden;
    white-space: nowrap;
  }

  .element {
    white-space: pre-wrap;
    width: 100%;
    margin-right: 2rem;
    display: inline-flex;
  }

  @media (min-width: 1024px) {
    .element {
      width: 550px;
    }
  }

  /* .vertical-section{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

main {
  overflow-x: hidden;
  display: flex;
  position: sticky;
  top:0;
}

h1 {
  margin: 0;
  padding: 0;
}

section {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4ch;
  scroll-snap-align: start;
} */

  .carousel-item {
    height: auto !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 45px !important;
    width: 45px !important;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23AC8865' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23AC8865' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  }

  .carousel-caption {
    position: unset !important;
  }
</style>
