<template>
  <div>
    <b-modal v-model="deleteModal.show" title="刪除警告" ok-only>
      <template #modal-footer>
        <button v-b-modal.modal-close_visit class="btn btn-success btn-sm m-1" @click="functionDelete">
          確定
        </button>
      </template>
      <p class="my-4">標題:{{ deleteModal.title }}</p>
      <p class="my-4">請問確定要刪除嗎?</p>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apiStore: (data) => this.userRequest.post("Default/Store", data),
    };
  },
  props: ["deleteModal"],
  methods: {
    toastShow() {
      this.$bvToast.toast("刪除成功", {
        title: "提示",
        solid: true,
      });
      this.$emit("init");
    },
    functionDelete() {
      if (this.deleteModal.type == "store") {
        let json = {
          TYPE: "DELETE",
          STORE_DELETE: {
            SO_ID: this.deleteModal.id,
          },
        };
        this.FunctionToken(this.APIStoreDelete, json);
      }
      this.deleteModal.show = false;
    },
    //api
    APIStoreDelete(data) {
      this.apiStore(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.toastShow();
          } else {
            alert(json.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
