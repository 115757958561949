<template>
  <div class="Page inner">
      <div class="innerPageHead">
        <b-container>
          <b-breadcrumb :items="items"></b-breadcrumb>
          <div>
            <b-form class="mt-5">
              <b-form-group label="帳號" label-for="username" class="text-left">
                <b-form-input
                  type="text"
                  placeholder="請輸入帳號"
                  v-model="login.AC_USERID"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="密碼" label-for="password" class="text-left">
                <b-form-input
                  type="password"
                  placeholder="請輸入密碼"
                  v-model="login.AC_PWD"
                ></b-form-input>
              </b-form-group>
              <b-row
                ><b-col>
                  <b-form-group
                    label="圖形驗證碼"
                    label-for="code"
                    class="text-left"
                  >
                    <b-form-input
                      id="code"
                      type="number"
                      placeholder="請輸入圖形驗證碼"
                      v-model="login.code"
                    ></b-form-input> </b-form-group></b-col
                ><b-col> <img width="50%" :src="imageCode" class="mb-3"/></b-col
              ></b-row>
              <b-row>
                <b-col>
                  <b-button size="lg" @click="functionLogin()" variant="success"
                    >登入</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-container>
      </div>
  </div>  
</template>
<script>
  export default {
    data() {
      return {
        imageCode: "",
        login: {
          AC_USERID: null,
          AC_PWD: null,
          code: null,
        },
        navigationActive: [],
        items: [
          {
            text: "首頁",
            href: "#/",
          },
          {
            text: "登入",
            active: true,
          },
        ],
        apiGetImageCode: (data) =>
          this.userRequest.post("Default/GetImageCode", data),
        apiAccount: (data) => this.userRequest.post("Default/Account", data),
      };
    },
    mounted() {
      this.functionCheckLogin();
    },
    methods: {
      functionLogin() {
        let json = {
          TYPE: "LOGIN",
          ACCOUNT_SELECT: {
            AC_USERID: this.login.AC_USERID,
            AC_PWD: this.login.AC_PWD,
            ImgCodePass: this.login.code,
          },
        };
        this.FunctionToken(this.ApiFunctionLogin, json);
      },
      functionCheckLogin() {
        let json = {
          TYPE: "CHECKSESSION",
          login_SELECT: {},
        };
        this.FunctionToken(this.APIFunctionCheckLogin, json);
      },
      //-----------api----------------
      APIFunctionCheckLogin(data) {
        this.apiAccount(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this._go("/admin/count");
            } else {
              this.APIFunctionGetImageCode();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      APIFunctionGetImageCode() {
        this.apiGetImageCode()
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this.imageCode = JSON.parse(json.Data).Image;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      ApiFunctionLogin(data) {
        this.apiAccount(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this._go("/admin/count");
            } else {
              this.APIFunctionGetImageCode();
              alert(json.Data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>
